import * as yup from 'yup';
import { useState, useEffect } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation, Trans } from 'react-i18next';
import { MAX_LONG_TEXT, MAX_LONG_NAME_LENGTH, ACCOUNT_TYPE } from '@allocamp/common';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import LoadingButton from '@mui/lab/LoadingButton';
import DeleteIcon from '@mui/icons-material/Delete';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import i18n from '../i18n.js';
import DestructiveDialog from '../components/DestructiveDialog.js';
import { YUP_DEFAULT_EMPTY_STRING } from '../yup.constants';
import PropertyModel from '../models/property.model.mjs';
import { useCreateAccount, useUpdateAccount, useDeleteAccount } from './useAccounts.js';
import { useSnackbar } from '../components/useSnackbar.js';

const AccountFormSchema = yup.object().shape({
    name: YUP_DEFAULT_EMPTY_STRING().required(() => i18n.t('accounts.validation.name')),
    type: YUP_DEFAULT_EMPTY_STRING().oneOf(Object.values(ACCOUNT_TYPE)).required(() => i18n.t('accounts.validation.type')),
    accountNumber: YUP_DEFAULT_EMPTY_STRING(MAX_LONG_NAME_LENGTH),
    ownerId: yup.string(),
    ownerOverride: YUP_DEFAULT_EMPTY_STRING(),
    contactName: YUP_DEFAULT_EMPTY_STRING(),
    contactPhone: YUP_DEFAULT_EMPTY_STRING(),
    contactEmail: YUP_DEFAULT_EMPTY_STRING(),
    url: YUP_DEFAULT_EMPTY_STRING(MAX_LONG_TEXT),
    notes: YUP_DEFAULT_EMPTY_STRING(MAX_LONG_TEXT)
});

const RelationshipOwner = Object.freeze({
    MEMBER: 'accounts.form.member',
    NON_MEMBER: 'accounts.form.nonmember'
});

const AccountOwner = ({ formMethods: { control, setValue }, account, property }) => {
    const { t } = useTranslation();
    const [toggle, setToggle] = useState(account?.ownerOverride ? RelationshipOwner.NON_MEMBER : RelationshipOwner.MEMBER);

    const onToggle = (_, val) => {
        setToggle(val);
        switch (val) {
            case RelationshipOwner.MEMBER:
                setValue('ownerOverride', '');
                break;
            case RelationshipOwner.NON_MEMBER:
                setValue('ownerId', '');
                break;
            default: break;
        }
    };

    return (
        <Box display='flex' gap={1} flexDirection={{ xs: 'column', sm: 'row' }}>
            <ToggleButtonGroup
                value={toggle}
                exclusive
                onChange={onToggle}
                color='success'
            >
                <ToggleButton value={RelationshipOwner.MEMBER}>
                    {t(RelationshipOwner.MEMBER)}
                </ToggleButton>
                <ToggleButton value={RelationshipOwner.NON_MEMBER} sx={{ whiteSpace: 'nowrap' }}>
                    {t(RelationshipOwner.NON_MEMBER)}
                </ToggleButton>
            </ToggleButtonGroup>
            {toggle === RelationshipOwner.MEMBER &&
                <Box display='flex' alignItems='center' flexGrow={1} gap={1}>
                    <Controller name='ownerId' control={control}
                        render={({ field }) => (
                            <FormControl fullWidth>
                                <InputLabel id='member-selector-label'>{t('accounts.label.relationshipOwner')}</InputLabel>
                                <Select {...field}
                                    labelId='member-selector-label'
                                    label={t('accounts.label.relationshipOwner')}
                                >
                                    {property.memberModels.map(m => <MenuItem key={m.userId} value={m.userId}>{m.displayName}</MenuItem>)}
                                </Select>
                            </FormControl>
                        )}
                    />
                    <Box>
                        <Button
                            size='small'
                            variant='outlined'
                            onClick={() => setValue('ownerId', '')}
                        >{t('actions.clear')}</Button>
                    </Box>
                </Box>
            }
            {toggle === RelationshipOwner.NON_MEMBER &&
                <Controller name='ownerOverride' control={control}
                    render={({ field, fieldState }) => (
                        <TextField {...field} fullWidth
                            label={t('accounts.label.relationshipOwner')}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                        />
                    )}
                />
            }
        </Box>
    );
};

const ControlledTextField = ({ name, label, control, required, multiline }) => (
    <Controller name={name} control={control}
        render={({ field, fieldState }) => (
            <TextField {...field} fullWidth
                label={label}
                error={!!fieldState.error}
                helperText={fieldState.error?.message}
                required={required}
                multiline={multiline}
            />
        )}
    />
);

const AccountForm = ({ account, onFormBusy, onFormCompleted, property: propertyDto }) => {
    const { t } = useTranslation();
    const [dialogOpen, setDialogOpen] = useState(false);
    const { createAccount, loading: createAccountLoading } = useCreateAccount();
    const { updateAccount, loading: updateAccountLoading } = useUpdateAccount();
    const { deleteAccount, loading: deleteAccountLoading } = useDeleteAccount();
    const { displaySuccess, displayError } = useSnackbar();

    const formMethods = useForm({
        defaultValues: {
            name: account?.name ?? '',
            type: account?.type ?? '',
            accountNumber: account?.accountNumber ?? '',
            ownerId: account?.ownerId ?? '',
            ownerOverride: account?.ownerOverride ?? '',
            contactName: account?.contactName ?? '',
            contactPhone: account?.contactPhone ?? '',
            contactEmail: account?.contactEmail ?? '',
            url: account?.url ?? '',
            notes: account?.notes ?? '',
        },
        mode: 'all',
        resolver: yupResolver(AccountFormSchema)
    });

    const formLoading = createAccountLoading || updateAccountLoading || deleteAccountLoading;
    useEffect(() => {
        onFormBusy?.()
    }, [formLoading, onFormBusy]);

    const { handleSubmit, control, formState: { isValid, isSubmitting } } = formMethods;
    const isUpdate = !!account;
    const property = new PropertyModel(propertyDto);

    const onSubmit = async (data) => {
        const input = { ...data };

        // propertyId is required on creation, but not update
        if (!isUpdate) input.propertyId = property._id;

        // if ownerId is not set, remove it from the input
        if (!isUpdate && input.ownerId === '') delete input.ownerId;

        return isUpdate
            ? updateAccount(account._id, input, {
                onError: (error) => displayError(error.display()),
                onCompleted: (accountModel) => {
                    onFormCompleted?.();
                    displaySuccess(t('accounts.form.updateSuccess', { name: accountModel.name }));
                }
            })
            : createAccount(input, {
                onError: (error) => displayError(error.display()),
                onCompleted: (accountModel) => {
                    onFormCompleted?.();
                    displaySuccess(t('accounts.form.createSuccess', { name: accountModel.name }));
                }
            });
    };
    const onDelete = async () => deleteAccount(account._id, {
        onError: (error) => displayError(error.display()),
        onCompleted: () => {
            onFormCompleted?.();
            displaySuccess(t('accounts.form.deleteSuccess', { name: account.name }));
        }
    });

    return (
        <>
            <Box display='flex' alignItems='center' gap={2} justifyContent={{ xs: 'space-between', sm: 'flex-start' }} sx={{ pb: 1 }}>
                <Typography variant='h6'>{isUpdate ? t('accounts.form.editTitle') : t('accounts.form.createTitle')}</Typography>
                {isUpdate &&
                    <Button variant='outlined' color='error' startIcon={<DeleteIcon />} size='small' onClick={() => setDialogOpen(true)}>
                        {t('actions.delete')}
                    </Button>
                }
            </Box>
            <form onSubmit={handleSubmit(onSubmit)}>
                <Stack spacing={1}>
                    <ControlledTextField name='name' label={t('accounts.form.name')} control={control} required />
                    <Controller name='type' control={control}
                        render={({ field }) => (
                            <FormControl fullWidth required>
                                <InputLabel id='type-selector-label'>{t('accounts.form.type')}</InputLabel>
                                <Select {...field}
                                    labelId='type-selector-label'
                                    label={t('accounts.form.type')}
                                >
                                    {Object.values(ACCOUNT_TYPE).map(at => <MenuItem key={at} value={at}>{t(`accounts.type.${at}`)}</MenuItem>)}
                                </Select>
                            </FormControl>
                        )}
                    />
                    <ControlledTextField name='accountNumber' label={t('accounts.label.accountNumber')} control={control} />
                    <ControlledTextField name='url' label={t('accounts.form.website')} control={control} />
                    <ControlledTextField name='contactName' label={t('accounts.label.contactName')} control={control} />
                    <ControlledTextField name='contactPhone' label={t('accounts.label.contactPhone')} control={control} />
                    <ControlledTextField name='contactEmail' label={t('accounts.label.contactEmail')} control={control} />
                    <AccountOwner formMethods={formMethods} property={property} account={account} />
                    <ControlledTextField name='notes' label={t('accounts.label.notes')} control={control} multiline />

                    <Box display='flex' gap={1} sx={{ pt: 2 }}>
                        <LoadingButton
                            variant='contained'
                            type='submit'
                            disabled={!isValid || isSubmitting}
                            loading={isSubmitting}
                        >{t('actions.save')}</LoadingButton>
                        <Button
                            variant='outlined'
                            onClick={() => onFormCompleted?.()}
                        >{t('actions.close')}</Button>
                    </Box>
                </Stack>
            </form>
            <DestructiveDialog
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
                loading={deleteAccountLoading}
                dialogContent={'Delete account?'}
                onDialogConfirm={async (closeDialog) => {
                    await onDelete();
                    closeDialog();
                }}
            >
                <Typography>
                    <Trans i18nKey='accounts.form.deleteConfirm'>
                        <strong>{{ accountName: account?.name }}</strong>
                    </Trans>
                </Typography>
            </DestructiveDialog>
        </>
    );
};

export default AccountForm;