import { useQuery, gql } from '@apollo/client';
import { eventModelFactory } from '../models/event.model.mjs';

const EVENTS_QUERY = gql`
    query EventFeed($first: Int, $after: String, $filter: EventFilter) {
        eventFeed(first: $first, after: $after, filter: $filter) {
            edges {
                cursor
                node {
                    _id createdAt eventName eventAction parameters
                    user { _id picture displayName email }
                    property { _id name }
                }
            }
            pageInfo {
                total startCursor endCursor hasPreviousPage hasNextPage
            }
        }
    }
`;

export const useEvents = () => {
    const { data, loading, error, fetchMore, networkStatus } = useQuery(EVENTS_QUERY, {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true
    });

    const loadMore = () => {
        return fetchMore({
            query: EVENTS_QUERY,
            notifyOnNetworkStatusChange: true,
            variables: {
                after: data.eventFeed.pageInfo.endCursor
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges = fetchMoreResult?.eventFeed.edges;
                const pageInfo = fetchMoreResult?.eventFeed.pageInfo;

                return fetchMoreResult && newEdges.length > 0
                    ? {
                        eventFeed: {
                            __typename: previousResult.eventFeed.__typename,
                            edges: [...previousResult.eventFeed.edges, ...newEdges],
                            pageInfo
                        }
                    }
                    : previousResult;
            }
        })
    };

    return {
        events: data?.eventFeed.edges.map(({ node }) => eventModelFactory(node)) ?? [],
        hasNextPage: data?.eventFeed.pageInfo.hasNextPage ?? true,
        count: data?.eventFeed.edges.length ?? 0,
        networkStatus,
        loading,
        error,
        loadMore
    };
};