import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';

import BasicTabs from '../components/BasicTabs';
import OverviewPropertyTab from './OverviewPropertyTab';
import ReservationsPropertyTab from './ReservationsPropertyTab';
import MembershipPropertyTab from './MembershipPropertyTab';
import SettingsPropertyTab from './SettingsPropertyTab';
import ExpensesPropertyTab from './ExpensesPropertyTab';
import AccountsPropertyTab from './AccountsPropertyTab';
import Error from '../components/Error';
import AllocampHelmet from '../components/AllocampHelmet';
import { usePropertyById } from './useProperty.js';
import { PropertyClaims } from '../models/property.model.mjs';
import { useAuth0 } from '../auth/useAuth.js';
import FullPageLoad from '../components/FullPageLoad';

const TABS = [
    { slug: 'overview', i18nKey: 'properties.detailTabs.overview', component: OverviewPropertyTab },
    { slug: 'reservations', i18nKey: 'properties.detailTabs.reservations', component: ReservationsPropertyTab },
    { slug: 'expenses', i18nKey: 'properties.detailTabs.expenses', component: ExpensesPropertyTab },
    { slug: 'accounts', i18nKey: 'properties.detailTabs.accounts', component: AccountsPropertyTab },
    { slug: 'membership', i18nKey: 'properties.detailTabs.membership', component: MembershipPropertyTab },
    {
        slug: 'settings', i18nKey: 'properties.detailTabs.settings', component: SettingsPropertyTab,
        claims: [PropertyClaims.UpdateProperty, PropertyClaims.DeleteProperty]
    }
];

const PropertyDetails = () => {
    const params = useParams();
    const { t } = useTranslation();
    const { auth0User } = useAuth0();

    // query property details
    const propertyById = usePropertyById({ id: params.id });
    if (propertyById.error) return <Error error={propertyById.error} />;

    // filter based on user privileges
    const tabs = auth0User?.id && propertyById.model
        ? TABS.filter(tab => !tab.claims || propertyById.model.memberWithIdHasClaims(auth0User.id, ...tab.claims))
        : [];

    return (
        <Container>
            <AllocampHelmet text={propertyById.model?.name} />
            {propertyById.loading
                ? <Box sx={{ py: 3 }}>
                    <FullPageLoad label={t('properties.detailsLoading')} />
                </Box>
                : <BasicTabs tabs={tabs} panelData={propertyById} />
            }
        </Container>
    );
};

export default PropertyDetails;