import { useRef, useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import dayjs from 'dayjs';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import CircularProgress from '@mui/material/CircularProgress';
import ButtonGroup from '@mui/material/ButtonGroup';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import FullCalendar from '@fullcalendar/react';
import multiMonthPlugin from '@fullcalendar/multimonth';
import interactionPlugin from '@fullcalendar/interaction';

import { DATE_FORMAT } from '@allocamp/common';
import { ReservationModel } from '../models/reservation.model.mjs';
import { useReservations } from '../reservation/useReservations.js';
import { colorForHash, contrastColor } from '../components/colors.js';
import { useIsSmall, useWindowDimensions } from '../useWindowDimensions.js';

const createFilterForQuery = (property, date) => ({
    propertyIds: property ? [property._id] : undefined,
    owner: 'all',
    startDate: date.startOf('year').format(DATE_FORMAT),
    endDate: date.endOf('year').format(DATE_FORMAT)
});

// https://github.com/fullcalendar/fullcalendar-examples/blob/main/react/src/DemoApp.jsx
const ReservationCalendar = ({ property, heightOffset }) => {
    const calendarRef = useRef(null);
    const [year, setYear] = useState(dayjs());
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { height } = useWindowDimensions();
    const isSmall = useIsSmall();

    const filter = createFilterForQuery(property, year);
    const { reservations, setFilter, loading, hasNextPage } = useReservations({ filter });

    const mergeEventColor = (evt) => {
        const color = colorForHash(evt.colorHash);
        return {
            ...evt,
            backgroundColor: color,
            borderColor: color,
            textColor: contrastColor(color)
        };
    };
    const handleEventClick = (clickInfo) => {
        // this will ensure the scroll bar stays put
        setYear(dayjs(clickInfo.event.end));

        searchParams.set('resdetail', clickInfo.event.id);
        setSearchParams(searchParams);
    };
    const handleDatesSelected = (selectionInfo) => {
        // this will ensure the scroll bar stays put
        setYear(dayjs(selectionInfo.end));

        const inclusiveDate = dayjs(selectionInfo.endStr).add(-1, 'day').format(DATE_FORMAT);
        searchParams.set('rescreate', `${selectionInfo.startStr},${inclusiveDate}`);
        setSearchParams(searchParams);
    };

    useEffect(() => {
        setFilter(filter);
        calendarRef.current.getApi().gotoDate(year.toDate());
    }, [year, calendarRef, filter, setFilter]);

    const today = () => setYear(dayjs());
    const nextYear = () => setYear(y => y.add(1, 'year'));
    const prevYear = () => setYear(y => y.add(-1, 'year'));

    if (hasNextPage) {
        console.warn('TODO hasNextPage is true')
    }

    return (
        <Box width={1}>
            <Typography variant='h5'>
                {t('reservations.reservationsTitle')}
            </Typography>

            <Box sx={{ my: 1 }} display='flex' justifyContent='space-between' alignItems='center'>
                <Box display='flex' gap={1} alignItems='center'>
                    <Typography variant='h6'>{year.format('YYYY')}</Typography>
                    {loading && <CircularProgress size={'1.5rem'} />}
                </Box>
                <ButtonGroup size='small' variant='contained' color='primary'>
                    <Button onClick={prevYear}>
                        <ChevronLeftIcon />
                    </Button>
                    <Button onClick={today}>{t('reservations.today')}</Button>
                    <Button onClick={nextYear}>
                        <ChevronRightIcon />
                    </Button>
                </ButtonGroup>
            </Box>

            {/*
              * Cannot select dates across months:
              * https://github.com/fullcalendar/fullcalendar/issues/7183 
            */}
            <FullCalendar
                ref={calendarRef}
                plugins={[interactionPlugin, multiMonthPlugin]}
                headerToolbar={null}
                initialView='multiMonthYear'
                initialDate={year.toDate()}
                editable={true}
                selectable={true}
                selectMinDistance={2}
                events={reservations?.map(r => mergeEventColor(new ReservationModel(r).asCalendarEvent(!property)))}
                eventClick={handleEventClick}
                select={handleDatesSelected}
                contentHeight={isSmall ? undefined : height - (heightOffset ?? 0)}
            //datesSet={dateInfo => console.log(dateInfo)}
            />

            {isSmall &&
                <Box sx={{ my: 1 }}>
                    <Typography variant='caption' color='text.secondary'>{t('reservations.dateRangeTip')}</Typography>
                </Box>
            }
        </Box>
    );
};

export default ReservationCalendar;