import { useState, useCallback } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { FixedSizeList } from 'react-window';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import InfiniteLoader from 'react-window-infinite-loader';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import dayjs from 'dayjs';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';

import { useEvents } from './useEvents';
import { useWindowDimensions, useIsSmall } from '../useWindowDimensions';

const LIST_HEIGHT = 400;
const ITEM_HEIGHT = 74;

const DetailEventCreated = ({ model, t }) => (
    <Typography variant='body2'>{model.details(t)}</Typography>
);
const DetailEventUpdated = ({ model, t }) => {
    const details = model.details(t);
    return (
        <Typography variant='body2'>
            <Typography component='span' variant='inherit' sx={{ textDecoration: 'line-through' }}>
                {details.original}
            </Typography>{details.original && ` → `}
            {details.current}
        </Typography>
    );
};

const DetailEventDeleted = ({ model, t }) => (
    <Typography variant='body2' sx={{ textDecoration: 'line-through' }}>
        {model.details(t)}
    </Typography>
);

const Timestamp = ({ ts }) => (
    <Typography variant='caption' color='text.secondary' fontStyle='italic'>
        {dayjs(ts).format('lll')}
    </Typography>
);

const getDetailComponent = (action) => {
    switch (action) {
        case 'created':
            return DetailEventCreated;
        case 'updated':
            return DetailEventUpdated;
        case 'deleted':
            return DetailEventDeleted;
        default:
            return null;
    }
};

const Row = ({ item: model, style, setSelected }) => {
    const { t } = useTranslation();

    const Detail = getDetailComponent(model.eventAction);
    return (
        <ListItemButton style={style} key={model.id} onClick={setSelected}>
            <ListItemAvatar>
                <Tooltip title={model.userDisplayName} arrow placement='right'>
                    <Avatar alt={model.userDisplayName} src={model.userPicture} />
                </Tooltip>
            </ListItemAvatar>
            <ListItemText
                primary={
                    <Box display='flex' flexDirection='column'>
                        <Typography variant='body1' noWrap>{model.description(t)}</Typography>
                        <Detail model={model} t={t} />
                    </Box>
                }
                secondary={<Timestamp ts={model.createdAt} />}
            />
        </ListItemButton>
    );
};
const LoadingRow = ({ index, style }) => (
    <ListItem style={style} key={index}>
        <Box display='flex' gap={2} width='100%'>
            <Skeleton variant='circular'>
                <Avatar />
            </Skeleton>
            <Box display='flex' flexGrow={1} flexDirection='column'>
                <Typography variant='body1'><Skeleton width='100%' /></Typography>
                <Typography variant='body2'><Skeleton width='100%' /></Typography>
            </Box>
        </Box>
    </ListItem>
);

const EmptyEvents = withTranslation()(({ t }) => (
    <Stack>
        <Box alignSelf='center'><AutoAwesomeIcon align='center' sx={{ my: 2, fontSize: 75 }} /></Box>
        <Typography align='left' color='text.secondary' sx={{ my: 1 }}>{t('events.empty')}</Typography>
    </Stack>
));

const ActivityDetailDialog = ({ model, onClose }) => {
    const { t } = useTranslation();

    const Detail = model ? getDetailComponent(model.eventAction) : null;
    const linkConfig = model?.link(t);
    return (
        <Dialog open={!!model} onClose={onClose}>
            <DialogTitle>{model?.description(t)}</DialogTitle>
            {model &&
                <DialogContent>
                    <Box display='flex' alignItems='center' gap={1} sx={{ mb: 1 }}>
                        <Avatar alt={model.userDisplayName} src={model.userPicture} />
                        <Typography>{model.userDisplayName}</Typography>
                    </Box>
                    <Detail model={model} t={t} />
                    {linkConfig &&
                        <Typography>
                            <Link href={linkConfig.path}>{linkConfig.display}</Link>
                        </Typography>
                    }
                    <Box sx={{ mt: 1 }}>
                        <Timestamp ts={model.createdAt} />
                    </Box>
                </DialogContent>
            }
        </Dialog>
    );
};

const ActivityList = () => {
    const { t } = useTranslation();
    const { height } = useWindowDimensions();
    const isSmall = useIsSmall();
    const [selected, setSelected] = useState(null);
    const onClose = useCallback(() => setSelected(null), [setSelected]);

    const {
        events,
        count,
        loading,
        loadMore,
        hasNextPage
    } = useEvents();

    const itemCount = hasNextPage ? count + 1 : count;
    const loadMoreItems = loading ? () => null : loadMore;
    const isItemLoaded = index => !hasNextPage || index < events.length;

    return (
        <>
            <Box width={1}>
                <Typography variant='h5'>{t('home.recentActivity')}</Typography>
                {!loading && itemCount === 0 && <EmptyEvents />}
                {itemCount > 0 &&
                    <Box sx={{ bgcolor: 'background.paper' }}>
                        <InfiniteLoader
                            isItemLoaded={isItemLoaded}
                            itemCount={itemCount}
                            loadMoreItems={loadMoreItems}
                        >
                            {({ onItemsRendered, ref }) => (
                                <FixedSizeList
                                    height={isSmall ? LIST_HEIGHT : height - 132}
                                    itemSize={ITEM_HEIGHT}
                                    itemCount={itemCount}
                                    onItemsRendered={onItemsRendered}
                                    ref={ref}
                                >
                                    {({ index, style }) => (
                                        isItemLoaded(index)
                                            ? <Row
                                                item={events[index]}
                                                style={style}
                                                setSelected={() => setSelected(events[index])}
                                            />
                                            : <LoadingRow index={index} style={style} />
                                    )}
                                </FixedSizeList>
                            )}
                        </InfiniteLoader>
                    </Box>
                }
            </Box>
            <ActivityDetailDialog model={selected} onClose={onClose} />
        </>
    );
};

export default ActivityList;