import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '../auth/useAuth.js';

const SignupButton = () => {
    const { loginWithRedirect } = useAuth0();
    const { t } = useTranslation();
    return (
        <Button color='inherit' variant='outlined' size='small' 
            onClick={() => loginWithRedirect({ authorizationParams: { screen_hint: 'signup' } })}
        >{t('actions.signup')}</Button>
    );
};

export default SignupButton;