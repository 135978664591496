import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useNavigate } from 'react-router-dom';
import { MAX_NAME_LENGTH, MAX_LONG_NAME_LENGTH } from '@allocamp/common';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Stack from '@mui/material/Stack';
import InputAdornment from '@mui/material/InputAdornment';
import MailIcon from '@mui/icons-material/Mail';
import PaymentsIcon from '@mui/icons-material/Payments';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import * as yup from 'yup';

import i18n from '../i18n.js';
import { routes } from '../routes.mjs';
import PropertyIconSelector from './PropertyIconSelector.js';
import { useCreateProperty, useUpdateProperty } from './useProperty.js';
import { useSnackbar } from '../components/useSnackbar.js';

const schema = yup.object({
    name: yup.string()
        .required(() => i18n.t('properties.validation.propertyNameRequired'))
        .max(MAX_NAME_LENGTH, ({ max, value }) => i18n.t('validation.nameMaxLength', { maxLength: max, currentLength: value.length })),
    icon: yup.string(),
    color: yup.string(),
    addressMailing: yup.string()
        .max(MAX_LONG_NAME_LENGTH, ({ max, value }) => i18n.t('validation.nameMaxLength', { maxLength: max, currentLength: value.length })),
    addressBilling: yup.string()
        .max(MAX_LONG_NAME_LENGTH, ({ max, value }) => i18n.t('validation.nameMaxLength', { maxLength: max, currentLength: value.length })),
}).required();

const PropertyForm = ({ property, disabled: disableOverride, nav, onFormSubmitted }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { displaySuccess, displayError } = useSnackbar();

    const { createProperty, loading: createPropertyLoading } = useCreateProperty();
    const { updateProperty, loading: updatePropertyLoading } = useUpdateProperty();

    const { control, handleSubmit, watch, setValue, formState: { isValid } } = useForm({
        defaultValues: {
            name: property?.name ?? '',
            icon: property?.icon ?? 'Forest',
            color: property?.color ?? 'lightGreen',
            addressMailing: property?.addressMailing ?? '',
            addressBilling: property?.addressBilling ?? '',
            billingIsSame: property?.addressMailing === property?.addressBilling ?? true
        },
        mode: 'all',
        resolver: yupResolver(schema)
    });
    const billingIsSameChecked = watch('billingIsSame');

    const onCompleted = ({ property, i18nKey }) => {
        displaySuccess(t(i18nKey, { propertyName: property.name }));
        onFormSubmitted?.(property);
        if (nav) navigate(routes.properties.dashboard);
    };
    const onError = (error) => displayError(error.display());

    const onSubmit = async (data) => {
        // resolve isBillingSame
        const input = { ...data, billingIsSame: undefined };
        if (data.billingIsSame) {
            input.addressBilling = input.addressMailing;
            setValue('addressBilling', input.addressMailing);
        }

        return property
            // property description is only set on the Overview tab
            ? updateProperty(property._id, input, {
                onError,
                onCompleted: (result) => onCompleted({ property: result, i18nKey: 'properties.notification.updateSuccess' })
            })
            : createProperty(input, {
                onError,
                onCompleted: (result) => onCompleted({ property: result, i18nKey: 'properties.notification.createSuccess' })
            });
    };

    const loading = createPropertyLoading || updatePropertyLoading;
    const formDisabled = loading || disableOverride;
    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Box sx={{ width: { xs: 1, sm: 500 } }}>
                <Stack spacing={2}>
                    <Controller name='name' control={control}
                        render={({ field, fieldState }) => (
                            <TextField {...field}
                                required
                                disabled={formDisabled}
                                label={t('properties.labels.name')}
                                placeholder={t('properties.labels.namePlaceholder')}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                            />
                        )}
                    />
                    <Controller name='addressMailing' control={control}
                        render={({ field, fieldState }) => (
                            <TextField {...field}
                                multiline
                                disabled={formDisabled}
                                label={t('properties.labels.mailing')}
                                error={!!fieldState.error}
                                helperText={fieldState.error?.message}
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position='start'>
                                            <MailIcon />
                                        </InputAdornment>
                                    )
                                }}
                            />
                        )}
                    />
                    <Controller name='billingIsSame' control={control}
                        render={({ field }) => (
                            <FormControlLabel
                                sx={{ mt: '0 !important' }}
                                disabled={formDisabled}
                                control={<Checkbox checked={field.value} onChange={e => field.onChange(e.target.checked)} />}
                                label={t('properties.labels.billingIsSame')}
                            />
                        )}
                    />

                    {!billingIsSameChecked &&
                        <Controller name='addressBilling' control={control}
                            render={({ field, fieldState }) => (
                                <TextField {...field}
                                    multiline
                                    disabled={formDisabled}
                                    label={t('properties.labels.billing')}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position='start'>
                                                <PaymentsIcon />
                                            </InputAdornment>
                                        )
                                    }}
                                />
                            )}
                        />
                    }
                </Stack>
            </Box>
            <Box sx={{ my: 1 }}>
                <PropertyIconSelector control={control} disabled={formDisabled} />
            </Box>
            <Stack direction='row' spacing={2}>
                <LoadingButton
                    variant='contained'
                    type='submit'
                    disabled={!isValid || formDisabled}
                    loading={loading}
                >{t('actions.save')}</LoadingButton>
                {nav && <Button onClick={() => navigate(-1)}>{t('actions.cancel')}</Button>}
            </Stack>
        </form>
    );
};

export default PropertyForm;