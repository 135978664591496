import dayjs from 'dayjs';
import { useState, useEffect } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert';
import Paper from '@mui/material/Paper';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import { useAccounts, useAccountById } from './useAccounts';
import Error from '../components/Error';
import AccountForm from './AccountForm';

const ExpandMore = styled(({ expand, ...other }) => {
    return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    })
}));

const AccountDetailSkeleton = () => (
    <Card variant='outlined' sx={{ p: 1 }}>
        <Typography variant='h6'><Skeleton width='100%' /></Typography>
        <Typography variant='subtitle2'><Skeleton width='100%' /></Typography>
    </Card>
);
const AccountsEmpty = withTranslation()(({ t }) => (
    <Box display='flex' flexDirection='column' gap={2}>
        <Alert severity='success'>
            <Typography variant='body1'><strong>{t('accounts.none')}</strong></Typography>
            <Typography variant='body2'>{t('accounts.purpose')}</Typography>
        </Alert>
    </Box>
));
const DetailRow = ({ label, value, valueProps, children }) => (
    value
        ? <TableRow>
            <TableCell sx={{ px: 1 }}><Typography variant='body2' fontWeight='bold'>{label}</Typography></TableCell>
            <TableCell sx={{ px: 0 }}>
                {children ? children : <Typography {...valueProps} variant='body2'>{value}</Typography>}
            </TableCell>
        </TableRow>
        : null
);
const OwnerMember = ({ account }) => (
    <ListItem disableGutters sx={{ py: 0 }}>
        <ListItemAvatar>
            <Avatar alt={account.ownerDisplayName} src={account.ownerPicture} />
        </ListItemAvatar>
        <ListItemText primary={account.ownerDisplayName} />
    </ListItem>
);
const Website = ({ account: acct }) => {
    const { t } = useTranslation();
    const { account } = useAccountById(acct.id);

    return (
        <DetailRow label={t('accounts.label.website')} value={account?.url}>
            <Link to={account?.url} target='_blank'>{account?.urlTitle ?? account?.url}</Link>
        </DetailRow>
    );
};
const AccountDetail = ({ account, expandOverride, resetExpandOverride, form, isEditing, onEdit }) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(expandOverride);

    useEffect(() => {
        if (expandOverride === null) return;
        setExpanded(expandOverride);
    }, [expandOverride, setExpanded]);

    const handleToggle = () => {
        resetExpandOverride();
        setExpanded(toggle => !toggle);
    };

    return (
        <Card variant='outlined' sx={{ p: 1 }}>
            {isEditing
                ? <AccountForm account={account} property={form.property} onFormBusy={form.onFormBusy} onFormCompleted={form.onFormCompleted} />
                : <>
                    <CardActionArea onClick={handleToggle}>
                        <Box display='flex' alignItems='center'>
                            <Box flexGrow={1}>
                                <Typography variant='h6'>{account.name}</Typography>
                                <Typography variant='subtitle2' color='text.secondary'>{t(account.typeI18nKey)}</Typography>
                            </Box>
                            <ExpandMore expand={expanded} />
                        </Box>
                    </CardActionArea>
                    <Collapse in={expanded} timeout='auto' mountOnEnter unmountOnExit>
                        <hr />
                        <Box display='flex' flexDirection='column' gap={1}>
                            <TableContainer>
                                <Table size='small'>
                                    <TableBody sx={{
                                        '& td': { border: 0 },
                                        '& td:first-of-type': { whiteSpace: { sm: 'nowrap' }, textAlign: 'right' },
                                        '& td:last-of-type': { width: '100%' }
                                    }}>
                                        <DetailRow label={t('accounts.label.accountNumber')} value={account.accountNumber} />
                                        <Website account={account} />
                                        <DetailRow label={t('accounts.label.contactName')} value={account.contactName} />
                                        <DetailRow label={t('accounts.label.contactPhone')} value={account.contactPhone}>
                                            <Link to={`tel:${account.contactPhone}`}>{account.contactPhone}</Link>
                                        </DetailRow>
                                        <DetailRow label={t('accounts.label.contactEmail')} value={account.contactEmail}>
                                            <Link to={`mailto:${account.contactEmail}`} target='_blank'>{account.contactEmail}</Link>
                                        </DetailRow>
                                        <DetailRow label={t('accounts.label.relationshipOwner')} value={account.ownerOverride} />
                                        <DetailRow label={t('accounts.label.relationshipOwner')} value={account.ownerEmail}>
                                            <OwnerMember account={account} />
                                        </DetailRow>
                                        <DetailRow label={t('accounts.label.notes')} value={account.notes} valueProps={{ style: { whiteSpace: 'pre-line' } }} />
                                    </TableBody>
                                </Table>
                            </TableContainer>
                            <Box>
                                <Button
                                    variant='outlined'
                                    size='small'
                                    color='success'
                                    onClick={() => onEdit(account._id)}
                                >{t('actions.edit')}</Button>
                            </Box>
                            <Typography variant='caption' color='text.secondary'>{t('label.lastModified', { ts: dayjs(account.lastModified).format('lll') })}</Typography>
                        </Box>
                    </Collapse>
                </>
            }
        </Card >
    );
}

const Accounts = ({ property }) => {
    const { t } = useTranslation();
    const { accounts, error, loading } = useAccounts(property._id);
    const [allExpanded, setAllExpanded] = useState(null);
    const [createExpanded, setCreateExpanded] = useState(false);
    const [updateAccount, setUpdateAccount] = useState(null);
    const [formBusy, setFormBusy] = useState(false);

    if (error) return <Error error={error} />;

    const resetExpandOverride = () => setAllExpanded(null);
    const onFormBusy = (busy) => setFormBusy(busy);
    const onFormCompleted = () => {
        setCreateExpanded(false);
        setUpdateAccount(null);
        setFormBusy(false);
    };
    return (
        <Box display='flex' flexDirection='column' gap={2} sx={{ width: { lg: '50%' } }}>
            <Typography variant='h5'>{t('accounts.title')}</Typography>
            <Box>
                {!createExpanded &&
                    <Button
                        disabled={loading || formBusy}
                        variant='contained'
                        onClick={() => setCreateExpanded(true)}
                    >{t('accounts.new')}</Button>
                }
                <Collapse in={createExpanded} timeout='auto' mountOnEnter unmountOnExit>
                    <Paper variant='outlined' sx={{ p: 2 }}>
                        <AccountForm property={property} onFormBusy={onFormBusy} onFormCompleted={onFormCompleted} />
                    </Paper>
                </Collapse>
            </Box>
            {loading
                ? <AccountDetailSkeleton />
                : (accounts.length > 0
                    ? <>
                        <Box display='flex' justifyContent={{ xs: 'space-between', sm: 'flex-start' }} gap={{ sm: 3 }}>
                            <Button size='small' onClick={() => setAllExpanded(true)}>Expand All</Button>
                            <Button size='small' onClick={() => setAllExpanded(false)}>Collapse All</Button>
                        </Box>
                        {accounts.map(a =>
                            <AccountDetail
                                key={a._id}
                                account={a}
                                expandOverride={allExpanded}
                                resetExpandOverride={resetExpandOverride}
                                isEditing={updateAccount === a._id}
                                onEdit={setUpdateAccount}
                                form={{ property, onFormBusy, onFormCompleted }}
                            />)
                        }
                    </>
                    : <AccountsEmpty />)
            }
        </Box>
    );
};

export default Accounts;