import { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { SessionComponent } from '../auth/Auth0Components';
import LoginButton from './LoginButton';
import SignupButton from './SignupButton';
import Logo from './Logo';
import { routes } from '../routes.mjs';
import { useAuth0 } from '../auth/useAuth.js';

const MED_BRAND_STYLE = {
    mr: 2,
    display: { xs: 'none', md: 'flex' },
    fontFamily: 'monospace',
    fontWeight: 700,
    letterSpacing: '.3rem',
    color: 'inherit',
    textDecoration: 'none',
};
const XS_BRAND_STYLE = {
    mr: 2,
    display: { xs: 'flex', md: 'none' },
    flexGrow: 1,
    fontFamily: 'monospace',
    fontWeight: 700,
    letterSpacing: '.3rem',
    color: 'inherit',
    textDecoration: 'none',
};

const LoginOrSignup = ({ showSignup }) => (
    <Box display='flex' flexDirection={{ xs: 'column', sm: 'row' }} justifyContent='center' flexGrow={0} gap={{ xs: 0, sm: 1 }}>
        <LoginButton />
        {showSignup &&
            <SignupButton />
        }
    </Box>
);

const ResponsiveAppBar = () => {
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [anchorElUser, setAnchorElUser] = useState(null);
    const { auth0User } = useAuth0();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };
    const handleLogout = async () => {
        setAnchorElUser(null);
        return navigate(routes.logout);
    };
    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    return (
        <AppBar position='static' sx={{ bgcolor: 'primary.light' }}>
            <Container maxWidth='xl'>
                <Toolbar disableGutters>

                    {/* Collapsed nav buttons */}
                    <Box sx={{ display: { xs: 'none', md: 'flex' }, mr: 1 }}><Logo /></Box>
                    <Typography variant='h6' noWrap component={RouterLink} to={routes.root} sx={MED_BRAND_STYLE}>
                        Allocamp
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                        <IconButton
                            size='large'
                            aria-label={t('aria.userAccount')}
                            aria-controls='menu-appbar'
                            aria-haspopup='true'
                            onClick={handleOpenNavMenu}
                            color='inherit'
                        >
                            <MenuIcon />
                        </IconButton>
                        <Menu
                            id='menu-appbar'
                            anchorEl={anchorElNav}
                            anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'left',
                            }}
                            keepMounted
                            transformOrigin={{
                                vertical: 'top',
                                horizontal: 'left',
                            }}
                            open={Boolean(anchorElNav)}
                            onClose={handleCloseNavMenu}
                            sx={{
                                display: { xs: 'block', md: 'none' },
                            }}
                        >
                            <UserActions
                                Cp={MenuItem}
                                handleCloseNavMenu={handleCloseNavMenu}
                                useTypography
                            />
                        </Menu>
                    </Box>

                    {/* Expanded nav button */}
                    <Box sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }}><Logo /></Box>
                    <Typography variant='h5' noWrap component={RouterLink} to={routes.root} sx={XS_BRAND_STYLE}>
                        Allocamp
                    </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                        <UserActions
                            Cp={Button}
                            handleCloseNavMenu={handleCloseNavMenu}
                            sx={{ my: 2, color: 'white', display: 'block' }}
                            user={auth0User}
                        />
                    </Box>

                    <SessionComponent noAuth={<LoginOrSignup showSignup={!isSmall} />}>
                        <UserSettings
                            user={auth0User}
                            handleOpenUserMenu={handleOpenUserMenu}
                            handleCloseUserMenu={handleCloseUserMenu}
                            handleLogout={handleLogout}
                            anchorElUser={anchorElUser}
                        />
                    </SessionComponent>

                </Toolbar>
            </Container>
        </AppBar>
    );
};
export default ResponsiveAppBar;

const NAV_ACTIONS_MAP = [
    { nav: routes.home, key: 'nav.home', requiresEmailVerification: false },
    { nav: routes.reservations.dashboard, key: 'nav.reservations', requiresEmailVerification: true },
    { nav: routes.properties.dashboard, key: 'nav.properties', requiresEmailVerification: true }
];
const UserActions = withTranslation()(({ Cp, handleCloseNavMenu, useTypography, t, tReady, user, ...rest }) => (
    <>
        {NAV_ACTIONS_MAP
            .map(item => (
                <Cp
                    key={item.key}
                    onClick={handleCloseNavMenu}
                    component={RouterLink}
                    to={item.nav}
                    disabled={item.requiresEmailVerification && user && !user?.emailVerified}
                    {...rest}
                >
                    {useTypography ? <Typography textAlign='center'>{t(item.key)}</Typography> : t(item.key)}
                </Cp>
            ))
        }
    </>
));
const UserSettings = withTranslation()(({ user, handleOpenUserMenu, anchorElUser, handleCloseUserMenu, handleLogout, t }) => (
    <Box sx={{ flexGrow: 0 }}>
        <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
            <Avatar alt={user.name} src={user.picture} />
        </IconButton>
        <Menu
            sx={{ mt: '45px' }}
            id='menu-appbar'
            anchorEl={anchorElUser}
            anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
        >
            <MenuItem onClick={handleCloseUserMenu} component={RouterLink} to={routes.profile}>
                <Typography textAlign='center'>{t('nav.profile')}</Typography>
            </MenuItem>
            <MenuItem onClick={handleLogout}>
                <Typography textAlign='center'>{t('actions.logout')}</Typography>
            </MenuItem>
        </Menu>
    </Box>
));