import {
    red, pink, purple, deepPurple,
    indigo, blue, lightBlue, cyan,
    teal, green, lightGreen, lime,
    yellow, amber, orange, deepOrange
} from '@mui/material/colors';

export const COLORS_MAP = Object.freeze({
    red, pink, purple, deepPurple,
    indigo, blue, lightBlue, cyan,
    teal, green, lightGreen, lime,
    yellow, amber, orange, deepOrange
});
const COLOR_KEYS = Object.keys(COLORS_MAP);

export const CONTRAST_COLOR = 'white';

export const colorFromName = (name, shade = 500) => COLORS_MAP[name][shade];
export const colorForHash = (hashInt, shade = 500) => COLORS_MAP[COLOR_KEYS[hashInt % COLOR_KEYS.length]][shade];
export const contrastColor = color => CONTRAST_COLOR;