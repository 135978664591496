import CommonPropertyTab from './CommonPropertyTab';
import Accounts from '../account/Accounts';

const AccountsPropertyTab = ({ property }) => {
    return (
        <CommonPropertyTab property={property}>
            <Accounts property={property} />
        </CommonPropertyTab >
    );
};

export default AccountsPropertyTab;