import AbstractModel from './model.mjs';

class MeModel extends AbstractModel {
    get provider() {
        return this.entity.provider;
    }
    get email() {
        return this.entity.email;
    }
    get emailVerified() {
        return this.entity.emailVerified;
    }

    get givenName() {
        return this.entity.givenName;
    }
    get familyName() {
        return this.entity.familyName;
    }
    get nickname () {
        return this.entity.nickname;
    }
    get displayName() {
        return this.entity.displayName;
    }
    get picture() {
        return this.entity.picture;
    }

    get propertyRoles() {
        return this.entity.properties;
    }
    get properties() {
        return this.entity.properties.map(pr => pr.property);
    }
    propertyCount() {
        return this.propertyRoles.length;
    }
    hasProperties() {
        return this.propertyCount() > 0;
    }
    canResetPassword() {
        return this.provider === 'auth0';
    }
}

export default MeModel;