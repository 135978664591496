import { useParams, Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';

import AllocampHelmet from '../components/AllocampHelmet';

const Properties = () => {
    // do not display Container wrapper for the PropertyDetails tabs
    const params = useParams();
    return (
        <>
            <AllocampHelmet i18nKey='nav.properties' />
            {!params.id
                ? <Container sx={{ py: 2 }}>
                    <Outlet />
                </Container>
                : <Outlet />
            }
        </>
    );
};

export default Properties;