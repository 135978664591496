import { useQuery, useMutation, gql } from '@apollo/client';

import PropertyModel from '../models/property.model.mjs';
import { ErrorModel } from '../models/error.model.mjs';

const USEPROPERTYBYID_QUERY_NAME = 'usePropertyById';

// this is needed for optimistic updates
export const PROPERTY_QUERY_FRAGMENT = `
_id name description icon color addressMailing addressBilling
members {
    _id role claims
    user {
        _id displayName email picture
    }
}
`;

const PROPERTYBYID_QUERY = gql`
    query ${USEPROPERTYBYID_QUERY_NAME}($id: ID!) {
        propertyById(id: $id) {
            ${PROPERTY_QUERY_FRAGMENT}
        }
    }
`;

const CREATE_PROPERTY = gql`
    mutation CreateProperty($input: PropertyInput!) {
        createProperty(input: $input) {
            ${PROPERTY_QUERY_FRAGMENT}
        }
    }
`;
const UPDATE_PROPERTY = gql`
    mutation UpdateProperty($id: ID!, $input: PropertyInput!) {
        updateProperty(id: $id, input: $input) {
            ${PROPERTY_QUERY_FRAGMENT}
        }
    }
`;
const DELETE_PROPERTY = gql`
    mutation DeleteProperty($id: ID!) {
        deleteProperty(id: $id)
    }
`;

export const usePropertyById = (props) => {
    const variables = { id: props.id };
    const { data, error, loading, refetch } = useQuery(PROPERTYBYID_QUERY, {
        variables,
        fetchPolicy: 'cache-and-network'
    });

    return {
        query: PROPERTYBYID_QUERY,
        variables,

        error,
        loading,
        refetch,

        property: data?.propertyById,
        model: data ? new PropertyModel(data.propertyById) : null
    };
};

export const useCreateProperty = () => {
    const [createProperty, createPropertyMutation] = useMutation(CREATE_PROPERTY);
    return {
        createProperty: (input, { onCompleted, onError } = {}) => createProperty({
            variables: { input },
            onCompleted: data => onCompleted?.(data.createProperty),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('createProperty', error)
        }),
        loading: createPropertyMutation.loading
    };
};

export const useUpdateProperty = () => {
    const [updateProperty, updatePropertyMutation] = useMutation(UPDATE_PROPERTY);
    return {
        updateProperty: (id, input, { onCompleted, onError } = {}) => updateProperty({
            variables: { id, input },
            onCompleted: data => onCompleted?.(data.updateProperty),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('updateProperty', error)
        }),
        loading: updatePropertyMutation.loading
    };
};

export const useDeleteProperty = () => {
    const [deleteProperty, deletePropertyMutation] = useMutation(DELETE_PROPERTY);
    return {
        deleteProperty: (id, { onCompleted, onError } = {}) => deleteProperty({
            variables: { id },
            onCompleted: data => onCompleted?.(data.deleteProperty),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('deleteProperty', error)
        }),
        loading: deletePropertyMutation.loading
    }
}

export const useUpdatePropertyDescription = () => {
    const [updateDescription, updateDescriptionMutation] = useMutation(UPDATE_PROPERTY);
    return {
        updateDescription: (id, description, property, { onCompleted, onError } = {}) => updateDescription({
            variables: { id, input: { description } },
            optimisticResponse: {
                updateProperty: {
                    ...property,
                    __typename: 'Property',
                    description: description
                }
            },
            onCompleted: data => onCompleted?.(data.updateProperty),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('updateDescription', error)
        }),
        loading: updateDescriptionMutation.loading
    };
};