import { useTranslation, Trans } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import EventIcon from '@mui/icons-material/Event';
import CabinIcon from '@mui/icons-material/Cabin';
import PriceCheckIcon from '@mui/icons-material/PriceCheck';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import ForestIcon from '@mui/icons-material/Forest';

const Landing = () => {
    const location = useLocation();
    const { t } = useTranslation();

    return (location.search.includes('?code=') 
        ? <Typography variant='body2'>{t('redirecting')}</Typography>
        : <Stack>
            <Alert severity='warning'>{t('alphaTesting')}</Alert>
            <Paper elevation={3} square sx={{ py: 6, px: 2 }}>
                <Grid container spacing={2}>
                    <Grid item sm={7} xs={12}>
                        <Trans i18nKey='landing.caption'>
                            <Typography align='center' variant='h4' />
                            <Typography align='center' variant='subtitle1' />
                        </Trans>
                    </Grid>
                    <Grid item sm={5} xs={12}>
                        <Box display='flex' justifyContent='center' alignItems='baseline' >
                            <CabinIcon sx={{ fontSize: 100 }} />
                            <ForestIcon color='success' sx={{ fontSize: 150 }} />
                        </Box>
                    </Grid>
                </Grid>
            </Paper>


            <Box sx={{ m: 3 }}>
                <Grid container spacing={3}>
                    <FeatureBox Icon={EventIcon} text={t('landing.feature1')} />
                    <FeatureBox Icon={PriceCheckIcon} text={t('landing.feature2')} />
                    <FeatureBox Icon={ManageAccountsIcon} text={t('landing.feature3')} />
                </Grid>
            </Box>

        </Stack>
    );
};

const FeatureBox = ({ Icon, text }) => (
    <Grid item sm={4} xs={12}>
        <Paper elevation={3}>
            <Box sx={{ height: 200, p: 2 }} display='flex' justifyContent='center' alignItems='center' flexDirection='column'>
                <Icon color='primary' sx={{ fontSize: 70 }} />
                <Typography align='center' variant='h5'>{text}</Typography>
            </Box>
        </Paper>
    </Grid>
);

export default Landing;