import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { Link as RouterLink, useLocation, useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Avatar from '@mui/material/Avatar';
import Skeleton from '@mui/material/Skeleton';
import DeleteIcon from '@mui/icons-material/Delete';
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { DATE_FORMAT } from '@allocamp/common';
import { useUpdateReservation, useDeleteReservation, useReservationById } from './useReservations.js';
import EmailAddress from '../components/EmailAddress.js';
import EditReservation, { UpdateReservationSchema } from './EditReservation.js';
import { ReservationModel } from '../models/reservation.model.mjs';
import { useSnackbar } from '../components/useSnackbar.js';
import { routes } from '../routes.mjs';

const REACTIVE_FORM = { my: 1, width: { xs: 1, sm: 500 } };
const SKELETON_HEIGHT = 70;
const DialogSkeleton = () => (
    <>
        <DialogTitle>
            <Typography>
                <Skeleton width={'100%'} />
            </Typography>
        </DialogTitle>
        <DialogContent>
            <Box display='flex' flexDirection='column' gap={1}>
                <Box display='flex' gap={1}>
                    <Skeleton variant='circular'>
                        <Avatar sx={{ width: 80, height: 80 }} />
                    </Skeleton>
                    <Box display='flex' flexDirection='column' flexGrow={1}>
                        <Typography variant='body1'><Skeleton width={'100%'} /></Typography>
                        <Typography variant='body2'><Skeleton width={'100%'} /></Typography>
                    </Box>
                </Box>
                <Box sx={REACTIVE_FORM}>
                    <Box display='flex' flexDirection='column'>
                        <Skeleton height={SKELETON_HEIGHT} width={'100%'} />
                        <Box display='flex' gap={1}>
                            <Skeleton height={SKELETON_HEIGHT} width={'100%'} />
                            <Skeleton height={SKELETON_HEIGHT} width={'100%'} />
                        </Box>
                    </Box>
                </Box>
            </Box>
        </DialogContent>
    </>
);

const ReservationDetailDialog = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { displaySuccess, displayError } = useSnackbar();
    
    const reservationId = searchParams.get('resdetail');
    const { reservation, loading: reservationLoading } = useReservationById(reservationId);

    const formMethods = useForm({
        defaultValues: { name: '', startDate: '', endDate: '' },
        mode: 'all',
        values: {
            name: reservation?.name ?? '',
            startDate: reservation?.startDate ? dayjs(reservation.startDate) : null,
            endDate: reservation?.endDate ? dayjs(reservation.endDate) : null
        },
        resolver: yupResolver(UpdateReservationSchema)
    });
    const { handleSubmit, formState: { isValid, isSubmitting } } = formMethods;

    const { deleteReservation } = useDeleteReservation();
    const { updateReservation } = useUpdateReservation();

    const closeDialog = () => {
        searchParams.delete('resdetail');
        setSearchParams(searchParams);
    };
    const onReservationUpdated = (data) => {
        closeDialog();
        return updateReservation(
            reservation._id,
            data.name,
            dayjs(data.startDate).format(DATE_FORMAT),
            dayjs(data.endDate).format(DATE_FORMAT),
            {
                onError: (error) => displayError(error.display()),
                onCompleted: (reservationModel) => {
                    const name = reservationModel.displayName(true);
                    displaySuccess(t('reservations.updateSuccess', { name }));
                }
            }
        );
    };
    const onReservationDeleted = () => {
        closeDialog();
        return deleteReservation(reservation._id, {
            onError: (error) => displayError(error.display()),
            onCompleted: () => {
                const name = new ReservationModel(reservation).displayName(true);
                displaySuccess(t('reservations.deleteSuccess', { name }));
            }
        });
    };

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    const canEdit = reservation?.canEdit ?? false;
    const canDelete = reservation?.canDelete ?? false;
    return (reservationId ?
        <Dialog open={!!reservationId} onClose={closeDialog} fullWidth={isSmall}>
            <form onSubmit={handleSubmit(onReservationUpdated)}>
                {!reservationLoading && reservation ?
                    <>
                        <DialogTitle>
                            {location.pathname !== routes.properties.view(reservation?.propertyId)
                                ? <Button
                                    size='large'
                                    component={RouterLink}
                                    to={routes.properties.view(reservation?.propertyId, 'reservations')}
                                >{reservation?.displayName()}</Button>
                                : reservation?.displayName()
                            }
                        </DialogTitle>
                        <DialogContent>
                            <Box display='flex' flexDirection='row' alignContent='center' sx={{ mb: 3 }}>
                                <Avatar alt={reservation.userDisplayName} src={reservation.userPicture} />
                                <Box sx={{ width: 10 }} />
                                <Box display='flex' flexDirection='column'>
                                    <Typography variant='body1' align='left'>{reservation.userDisplayName}</Typography>
                                    <EmailAddress email={reservation.userEmail} size='small' />
                                </Box>
                            </Box>
                            <EditReservation formMethods={formMethods} disabled={isSubmitting} readOnly={!canEdit} />
                        </DialogContent>
                    </>
                    : <DialogSkeleton />
                }
                {(canEdit || canDelete)
                    ? <Box display='flex' justifyContent='space-between' sx={{ mx: 2, mb: 1 }}>
                        {canDelete &&
                            <Button
                                onClick={onReservationDeleted}
                                color='error'
                                startIcon={<DeleteIcon />}
                                disabled={reservationLoading}
                            >
                                {t('actions.delete')}
                            </Button>
                        }
                        {canEdit &&
                            <Box display='flex' gap={1}>
                                <Button
                                    variant='contained'
                                    type='submit'
                                    disabled={!isValid || reservationLoading}
                                >{t('actions.save')}</Button>
                                <Button onClick={closeDialog}>{t('actions.cancel')}</Button>
                            </Box>
                        }
                    </Box>
                    : null
                }
            </form>
        </Dialog>
        : null
    );
};

export default ReservationDetailDialog;