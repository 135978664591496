import { Helmet } from 'react-helmet-async';
import { withTranslation } from 'react-i18next';

const AllocampHelmet = ({ i18nKey, text, t } = {text: ''}) => (
    <Helmet>
        <title>
            Allocamp {
            i18nKey ? `| ${t(i18nKey)}` 
                : text ? `| ${text}` : ''}
        </title>
    </Helmet>
);

export default withTranslation()(AllocampHelmet);