import AbstractModel from './model.mjs';

export class Auth0UserModel extends AbstractModel {
    get sub() {
        return this.entity.sub;
    }
    get id() {
        return this.namespace._id;
    }
    get name() {
        return this.entity.name;
    }
    get email() {
        return this.entity.email;
    }
    get emailVerified() {
        return this.entity.email_verified;
    }
    get picture() {
        return this.entity.picture;
    }

    get namespace() {
        return this.entity['https://www.allocamp.com'];
    }
}