import { useAuth0 as useAuth0Native } from '@auth0/auth0-react';
import { useQuery, useMutation, gql } from '@apollo/client';

import MeModel from '../models/me.model.mjs';
import { Auth0UserModel } from '../models/auth0.model.mjs';
import { ErrorModel } from '../models/error.model.mjs';

export const USEME_QUERY_NAME = 'Me';

export const USEME_QUERY_FRAGMENT = `
    _id email emailVerified provider givenName familyName nickname displayName picture
    properties {
        _id role claims
        property { _id name icon color }
    }
`;
const ME_QUERY = gql`
    query ${USEME_QUERY_NAME} {
        me {
            ${USEME_QUERY_FRAGMENT}
        }
    }
`;
const RESEND_QUERY = gql`
    mutation ResendVerificationEmail {
        resendVerificationEmail
    }
`;

const UPDATE_ME = gql`
    mutation UpdateMe($input: UpdateMeInput!) {
        updateMe(input: $input) {
            ${USEME_QUERY_FRAGMENT}
        }
    }
`;
const RESET_PASSWORD = gql`
    mutation ResetPassword($clientId: String!) {
        resetPassword(clientId: $clientId)
    }
`;

export const useMe = (props) => {
    const { data, error, loading } = useQuery(ME_QUERY, {
        fetchPolicy: 'cache-and-network',
        onCompleted: props?.onCompleted ? ({ me }) => props.onCompleted({ me, model: new MeModel(me) }) : null
    });

    return {
        error,
        loading,
        me: data?.me,
        model: data?.me ? new MeModel(data.me) : null
    };
};

export const useAuth0 = () => {
    const methods = useAuth0Native();
    return {
        auth0User: methods.user ? new Auth0UserModel(methods.user) : null,
        ...methods,
    };
};

export const useVerificationEmail = () => {
    const [resendVerificationEmail, resendVerificationEmailMutation] = useMutation(RESEND_QUERY);

    return {
        resendVerificationEmail: ({ onCompleted, onError } = {}) => resendVerificationEmail({
            onCompleted: data => onCompleted?.(data.resendVerificationEmail),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('resendVerificationEmail', error)
        }),
        loading: resendVerificationEmailMutation.loading
    };
};

export const useUpdateMe = () => {
    const [updateMe, updateMeMutation] = useMutation(UPDATE_ME);
    return {
        updateMe: (input, { onCompleted, onError } = {}) => updateMe({
            variables: { input },
            onCompleted: data => onCompleted?.(data.updateMe),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('updateMe', error)
        }),
        loading: updateMeMutation.loading
    };
};
export const useResetPassword = () => {
    const [resetPassword, resetPasswordMutation] = useMutation(RESET_PASSWORD);
    return {
        resetPassword: (clientId, { onCompleted, onError } = {}) => resetPassword({
            variables: { clientId },
            onCompleted: data => onCompleted?.(data.updateMe),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('useResetPassword', error)
        }),
        loading: resetPasswordMutation.loading
    };
};