import { useEffect } from 'react';
import { useTranslation, Trans, withTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useApolloClient } from '@apollo/client';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LogoutIcon from '@mui/icons-material/Logout';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { useAuth0 } from '../auth/useAuth.js';

const ResetPassword = withTranslation()(({ email, t }) => (
    <Box display='flex' flexDirection='column' sx={{ my: 3 }} alignItems='center'>
        <Typography variant='body1' align='center'>{t('logout.resetPassword.title')}</Typography>
        <Typography variant='body2' align='center'>
            <Trans i18nKey='logout.resetPassword.instructions'>
                <strong>{{ email: email ?? '' }}</strong>
            </Trans>
        </Typography>
    </Box>
));

const LogoutLanding = () => {
    const { t } = useTranslation();
    const [searchParams] = useSearchParams();
    const { isAuthenticated, logout } = useAuth0();
    const client = useApolloClient();

    const href = window.location.href;
    useEffect(() => {
        if (!isAuthenticated) return;
        const inner = async () => {
            await client.clearStore();
        };
        inner();
        logout({ logoutParams: { returnTo: href } });
    }, [isAuthenticated, logout, client, href]);

    return (
        <Container sx={{ py: 2 }}>
            <Box display='flex' flexDirection='column' alignItems='center'>
                {isAuthenticated
                    ? <>
                        <Typography variant='h5' align='center'>{t('logout.logoutActive')}</Typography>
                        <LogoutIcon sx={{ fontSize: 60 }} />
                    </>
                    : <>
                        <Typography variant='h5' align='center'>{t('logout.logoutComplete')}</Typography>
                        <CheckCircleOutlineIcon sx={{ fontSize: 60 }} color='success' />
                    </>
                }
            </Box>

            {searchParams.get('type') === 'resetpword' &&
                <ResetPassword />
            }
        </Container>
    );
};

export default LogoutLanding;