import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import IconButton from '@mui/material/IconButton';

const ExpandMore = styled(({ expand, ...other }) => {
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    })
}));

const DangerZone = ({
    sectionTitle, // section title
    disabled, // destructive button disabled
    loading, // destructive button loading
    dialogContent, // text inside dialog confirmation
    destructiveButtonText, // localized destructive button text
    onDialogConfirm, // callback for confirming destructive action
    notAvailableText, // if the destructive action is not possible for some reason
    children // text inside alert panel
}) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const [dialogOpen, setDialogOpen] = useState(false);

    const handleClose = () => setDialogOpen(false);

    return (
        <Box>
            <Box display='flex' alignItems='baseline'>
                <Typography variant='h5' sx={{ mt: 2 }}>{sectionTitle}</Typography>
                <Box>
                    <ExpandMore expand={expanded} onClick={() => setExpanded(toggle => !toggle)}>
                        <ExpandMoreIcon />
                    </ExpandMore>
                </Box>
            </Box>
            <Collapse in={expanded} timeout='auto'>
                {notAvailableText
                    ? <Typography variant='body2' color='text.secondary'>{notAvailableText}</Typography>
                    : <Alert severity='error' sx={{ my: 1 }}>
                        <Box display='flex' flexDirection='column'>
                            <Box>
                                <Typography variant='body2'>{children}</Typography>
                            </Box>
                            <Box sx={{ mt: 1 }}>
                                <LoadingButton
                                    variant='contained'
                                    color='error'
                                    disabled={disabled}
                                    loading={loading}
                                    onClick={() => setDialogOpen(true)}
                                >{destructiveButtonText}</LoadingButton>
                            </Box>
                        </Box>
                    </Alert>
                }
            </Collapse>
            <Dialog open={dialogOpen} onClose={handleClose}>
                <DialogContent>
                    <DialogContentText>{dialogContent}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <LoadingButton
                        variant='contained'
                        color='error'
                        disabled={disabled}
                        loading={loading}
                        onClick={() => onDialogConfirm(handleClose)}
                    >{destructiveButtonText}</LoadingButton>
                    <Button
                        disabled={loading}
                        onClick={handleClose}
                    >{t('actions.cancel')}</Button>
                </DialogActions>
            </Dialog>
        </Box>
    );
};

export default DangerZone;