import { Link as RouterLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Skeleton from '@mui/material/Skeleton';
import Tooltip from '@mui/material/Tooltip';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import Button from '@mui/material/Button';

import PropertyModel from '../models/property.model.mjs';
import Error from '../components/Error';
import { routes } from '../routes.mjs';
import { useMe } from '../auth/useAuth.js';
import { useCurrentOccupants } from '../reservation/useReservations.js';
import PropertyIcon from './PropertyIcon.js';

const CARD_WIDTH = 275;

const PropertyCard = ({ property }) => {
    const { t } = useTranslation();
    const propertyModel = new PropertyModel(property);
    const { count, loading: currentOccupantsLoading } = useCurrentOccupants(propertyModel.id);

    return (
        <Card sx={{ width: { xs: 1, sm: CARD_WIDTH }, maxWidth: { xs: 1, sm: CARD_WIDTH } }} variant='outlined'>
            <CardActionArea component={RouterLink} to={routes.properties.view(propertyModel.id)}>
                <CardHeader
                    title={
                        <Box display='flex' justifyContent='space-between' alignItems='center' gap={2}>
                            <Typography variant='h6' component='div'>
                                {propertyModel.name}
                            </Typography>
                            {!currentOccupantsLoading && count > 0 &&
                                <Tooltip title={t('reservations.tooltip.propertyOccupied')} arrow>
                                    <EventBusyIcon variant='h6' color='error' />
                                </Tooltip>
                            }
                            {!currentOccupantsLoading && count === 0 &&
                                <Tooltip title={t('reservations.tooltip.propertyAvailable')} arrow>
                                    <EventAvailableIcon variant='h6' color='success' />
                                </Tooltip>
                            }
                        </Box>
                    }
                />
                <PropertyIcon property={property} variant='none' square />
                <CardContent />
            </CardActionArea>
        </Card>
    );
};

const PropertyList = () => {
    const { t } = useTranslation();
    const { loading, error, model: meModel } = useMe();

    if (error) return <Error error={error} />;

    return (
        <Box>
            <Typography variant='h5'>{t('properties.listTitle')}</Typography>
            <Box sx={{ my: 2 }}>
                <Button
                    disabled={loading}
                    component={RouterLink}
                    to={routes.properties.create}
                    variant='contained'
                >{t('home.empty.createProperty')}</Button>
            </Box>
            {loading && !meModel?.hasProperties() &&
                <Skeleton variant='rectangular' width={CARD_WIDTH} height={200} />
            }
            {meModel?.hasProperties() &&
                <Box display='flex' flexWrap='wrap' gap={2} flexDirection={{ xs: 'column', sm: 'row' }}>
                    {meModel.propertyRoles.map(pr => <PropertyCard key={pr._id} property={pr.property} />)}
                </Box>
            }
            {!loading && !meModel?.hasProperties() &&
                <Typography variant='body1' color='text.secondary'>{t('properties.noProperties')}</Typography>
            }
        </Box>
    );
};

export default PropertyList;