import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import PropertyForm from './PropertyForm';
import AllocampHelmet from '../components/AllocampHelmet';

const NewProperty = () => {
    const { t } = useTranslation();
    const i18nKey = 'properties.newTitle';
    return (
        <Container sx={{ my: 3 }}>
            <AllocampHelmet i18nKey={i18nKey} />
            <Typography variant='h4' sx={{ mb: 4 }}>{t(i18nKey)}</Typography>
            <PropertyForm nav={true} />
        </Container>

    );
};

export default NewProperty;