import { useTranslation } from 'react-i18next';
import LoadingButton from '@mui/lab/LoadingButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';

const DestructiveDialog = ({
    dialogOpen,
    setDialogOpen,
    loading, // destructive button loading
    onDialogConfirm, // callback for confirming destructive action
    destructiveButtonText, // optional - localized destructive button text (default=Delete),
    dialogContent, // optional - text inside dialog confirmation
    children, // optional - custom dialog content
}) => {
    const { t } = useTranslation();
    const handleClose = () => setDialogOpen(false);

    return (
        <Dialog open={dialogOpen} onClose={handleClose}>
            <DialogContent>
                {children ?? <DialogContentText>{dialogContent}</DialogContentText>}
            </DialogContent>
            <DialogActions>
                <LoadingButton
                    variant='contained'
                    color='error'
                    loading={loading}
                    onClick={() => onDialogConfirm(handleClose)}
                >{destructiveButtonText ?? t('actions.delete')}</LoadingButton>
                <Button
                    disabled={loading}
                    onClick={handleClose}
                >{t('actions.cancel')}</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DestructiveDialog;