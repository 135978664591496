import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { withTranslation, useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import EditIcon from '@mui/icons-material/Edit';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import InputIcon from '@mui/icons-material/Input';
import PreviewIcon from '@mui/icons-material/Preview';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import EventBusyIcon from '@mui/icons-material/EventBusy';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
import PaymentsIcon from '@mui/icons-material/Payments';
import Tooltip from '@mui/material/Tooltip';
import Link from '@mui/material/Link';
import Skeleton from '@mui/material/Skeleton';
import Chip from '@mui/material/Chip';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import { MAX_LONG_TEXT } from '@allocamp/common';
import { useForm, Controller } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactMarkdown from 'react-markdown';
import dayjs from 'dayjs';

import i18n from '../i18n.js';
import './markdown-styles.css';
import { DATE_FORMAT } from '@allocamp/common';
import CommonPropertyTab from './CommonPropertyTab';
import { PropertyClaims } from '../models/property.model.mjs';
import { useReservations, useAvailability } from '../reservation/useReservations';
import ReservationDetailDialog from '../reservation/ReservationDetailDialog';
import { ReservationModel } from '../models/reservation.model.mjs';
import PropertyIcon from './PropertyIcon';
import { useAuth0 } from '../auth/useAuth.js';
import { useUpdatePropertyDescription } from './useProperty.js';
import { useSnackbarApolloCallback } from '../components/useSnackbar.js';

const InputMode = Object.freeze({
    Input: 'input',
    Preview: 'preview'
});

const schema = yup.object({
    description: yup.string().max(MAX_LONG_TEXT, ({ max, value }) => i18n.t('validation.nameMaxLength', { maxLength: max, currentLength: value.length }))
}).required();

const MarkdownBox = withTranslation()(({ children, t }) => (
    children
        ? <Box sx={{ border: '1px solid lightgray', borderRadius: '4px', px: 2 }}>
            <ReactMarkdown className='reactMarkdown'>
                {children}
            </ReactMarkdown>
        </Box>
        : <Typography color='text.secondary' sx={{ fontStyle: 'italic' }}>{t('properties.noContent')}</Typography>
));

const PropertyDescription = ({ property, canEdit, isEditingDescription, setEditingDescription, onEditingDone }) => {
    const { t } = useTranslation();
    const [inputMode, setInputMode] = useState(InputMode.Input);
    const { updateDescription, loading } = useUpdatePropertyDescription();
    const { callbackWithError } = useSnackbarApolloCallback();

    const { control, handleSubmit, getValues, resetField, formState: { isValid } } = useForm({
        defaultValues: {
            description: ''
        },
        values: {
            description: property.description ?? ''
        },
        mode: 'all',
        resolver: yupResolver(schema)
    });

    const handleInputModeChanged = (_, val) => {
        if (val !== null) {
            setInputMode(val);
        }
    };
    const handleCancel = () => {
        resetField('description');
        setEditingDescription(false);
        setInputMode(InputMode.Input);
        onEditingDone();
    };
    const onSubmit = (data) => {
        setEditingDescription(false);
        setInputMode(InputMode.Input);
        onEditingDone();
        return updateDescription(property._id, data.description, property, callbackWithError());
    };

    return (isEditingDescription
        ? <Box display='flex' gap={1}>
            <Box display='flex' flexDirection='column' flexGrow={1}>
                <form onSubmit={handleSubmit(onSubmit)}>
                    {inputMode === InputMode.Input &&
                        <Controller name='description' control={control}
                            render={({ field, fieldState }) => (
                                <TextField {...field}
                                    autoFocus
                                    fullWidth
                                    multiline
                                    rows={10}
                                    disabled={loading}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                    placeholder={t('properties.labels.descriptionPlaceholder')}
                                />
                            )}
                        />
                    }
                    {inputMode === InputMode.Preview &&
                        <MarkdownBox>{getValues('description')}</MarkdownBox>
                    }
                    <Box display='flex' gap={1} sx={{ my: 1 }} justifyContent='flex-end' alignItems='center'>
                        <Link href='https://www.markdownguide.org/basic-syntax/' variant='body2' rel='noreferrer' target='_blank'>
                            {t('properties.markdownGuide')}
                        </Link>
                        <Button
                            variant='contained'
                            type='submit'
                            disabled={!isValid || loading}
                        >{t('actions.save')}</Button>
                        <Button
                            onClick={handleCancel}
                            disabled={loading}
                        >{t('actions.cancel')}</Button>
                    </Box>
                </form>
            </Box>
            <ToggleButtonGroup
                orientation='vertical'
                value={inputMode}
                exclusive
                onChange={handleInputModeChanged}
            >
                <ToggleButton value={InputMode.Input}>
                    <Tooltip title={t('properties.tooltip.input')} placement='left' arrow>
                        <InputIcon />
                    </Tooltip>
                </ToggleButton>
                <ToggleButton value={InputMode.Preview}>
                    <Tooltip title={t('properties.tooltip.preview')} placement='left' arrow>
                        <PreviewIcon />
                    </Tooltip>
                </ToggleButton>
            </ToggleButtonGroup>
        </Box >

        : <Box>
            <MarkdownBox>{property.description ?? ''}</MarkdownBox>
            <Box display='flex' gap={1} sx={{ my: 1 }}>
                {canEdit &&
                    <LoadingButton
                        onClick={() => setEditingDescription(true)}
                        startIcon={<EditIcon />}
                        disabled={loading}
                        loading={loading}
                    >{t('actions.edit')}</LoadingButton>}
            </Box>
        </Box>
    );
};

const MemberReservationList = ({ reservations, handleReservationClicked }) => {
    return (
        <Box display='flex' gap={1} flexWrap='wrap'>
            {reservations.map(r =>
                <Chip
                    key={r._id}
                    avatar={<Avatar alt={r.user.displayName} src={r.user.picture} />}
                    label={r.user.displayName}
                    variant='outlined'
                    size='small'
                    onClick={() => handleReservationClicked(r)}
                />
            )}
        </Box>
    );
};

const CurrentOccupants = ({ property, availabilityQuery, refreshAll }) => {
    const { t } = useTranslation();

    const today = dayjs().format(DATE_FORMAT);
    const filter = {
        propertyIds: [property._id],
        startDate: today,
        endDate: today
    };

    const { availability } = availabilityQuery;
    const reservationsTitle = () => availability?.nextAvailableDate
        ? t('reservations.notAvailableUntil', { date: dayjs(availability.nextAvailableDate).format('MMM D, YYYY') })
        : null;

    const iconColor = (reservations) => reservations?.length > 0 ? 'error' : 'success';

    return (
        <Occupants
            refreshAll={refreshAll}
            availabilityQuery={availabilityQuery}
            filter={filter}
            tooltip={t('reservations.tooltip.occupants')}
            reservationsTitle={reservationsTitle}
            noReservationsTitle={t('reservations.noOccupants')}
            reservationsIcon={EventBusyIcon}
            noReservationsIcon={EventAvailableIcon}
            iconColor={iconColor}
        />
    );
};

const SelfOccupant = ({ property, availabilityQuery, refreshAll }) => {
    const { t } = useTranslation();

    const filter = {
        owner: 'self',
        propertyIds: [property._id],
        startDate: dayjs().format(DATE_FORMAT)
    };

    const reservationsTitle = (reservations) => {
        if (reservations?.length === 1) {
            const res = reservations[0];
            return dayjs(res.startDate).isSameOrBefore(dayjs(), 'day')
                ? t('reservations.currentReservationSelf', { date: new ReservationModel(res).dateRangeDisplay() })
                : t('reservations.nextReservationSelf', { date: dayjs(res.startDate).format('MMM D, YYYY') });
        } else {
            return null;
        }
    };

    return (
        <Occupants
            refreshAll={refreshAll}
            availabilityQuery={availabilityQuery}
            filter={filter}
            tooltip={t('reservations.tooltip.reservationSelf')}
            reservationsTitle={reservationsTitle}
            noReservationsTitle={t('reservations.noReservationsSelf')}
            reservationsIcon={PersonIcon}
            noReservationsIcon={PersonIcon}
            limit={1}
            preventLoadMore={true}
        />
    );
};

const Occupants = ({
    availabilityQuery,
    refreshAll,
    filter,
    limit,
    tooltip,
    reservationsTitle,
    noReservationsTitle,
    reservationsIcon,
    noReservationsIcon,
    iconColor,
    preventLoadMore
}) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();

    const {
        loading, reservations, count, hasNextPage, loadMore,
        refetch: refetchReservations, setFilter
    } = useReservations({ filter, limit });

    const { refetch: refetchAvailability } = availabilityQuery;
    const { shouldRefreshAll, setRefreshAll } = refreshAll;

    useEffect(() => {
        if (!shouldRefreshAll) return;
        setRefreshAll(false);

        const inner = async () => {
            await refetchAvailability();
            await refetchReservations();
        };
        inner();
    }, [shouldRefreshAll, setRefreshAll, refetchAvailability, refetchReservations]);

    useEffect(() => {
        if (!filter) return;
        setFilter(filter);
    }, [filter, setFilter]);

    const handleReservationClicked = (res) => {
        searchParams.set('resdetail', res._id);
        setSearchParams(searchParams);
    };

    const Icon = availabilityQuery.loading || loading || count === 0 ? noReservationsIcon : reservationsIcon;
    return (
        <>
            <Box display='flex' gap={2} sx={{ my: 1 }} alignItems='center'>
                <Tooltip placement='right' arrow title={tooltip}>
                    <Icon color={iconColor?.(reservations)} sx={{ fontSize: 35 }} />
                </Tooltip>
                {loading && count === 0 &&
                    <Box width='250px'>
                        <Typography variant='body1'><Skeleton variant='rectangle' /></Typography>
                    </Box>
                }
                {count > 0 &&
                    <Box>
                        <Typography variant='body2' color='text.secondary'>{reservationsTitle(reservations)}</Typography>
                        <MemberReservationList reservations={reservations} handleReservationClicked={handleReservationClicked} />
                        {!preventLoadMore && hasNextPage && <Button size='small' onClick={loadMore}>{t('reservations.loadMore')}</Button>}
                    </Box>
                }
                {!loading && count === 0 &&
                    <Typography variant='body1' color='text.secondary'>{noReservationsTitle}</Typography>
                }
            </Box>
        </>
    );
};

const AddressDisplay = withTranslation()(({ property, t }) => (
    <Box display='flex' flexDirection='column'>
        {property?.addressMailing &&
            <Box display='flex' alignContent='baseline' gap={1}>
                <Tooltip
                    title={
                        t(property?.addressBilling === property?.addressMailing
                            ? 'properties.labels.both'
                            : 'properties.labels.mailing')
                    }
                    arrow placement='right'
                >
                    <Typography color='text.secondary'>
                        <MailIcon />
                        {property?.addressBilling === property?.addressMailing &&
                            <PaymentsIcon />
                        }
                    </Typography>
                </Tooltip>
                <Typography color='text.secondary'>{property?.addressMailing}</Typography>
            </Box>
        }
        {property?.addressBilling && property?.addressBilling !== property?.addressMailing &&
            <Box display='flex' alignContent='baseline' gap={1}>
                <Tooltip title={t('properties.labels.billing')} arrow placement='right'>
                    <Typography color='text.secondary'><PaymentsIcon /></Typography>
                </Tooltip>
                <Typography color='text.secondary'>{property?.addressBilling}</Typography>
            </Box>
        }
    </Box>
));

const OverviewPropertyTab = ({ property, model }) => {
    const { t } = useTranslation();
    const { auth0User } = useAuth0();
    const [firstEditButton, setFirstEditButton] = useState(false);
    const [isEditingDescription, setEditingDescription] = useState(false);
    const [shouldRefreshAll, setRefreshAll] = useState(false);
    const availabilityQuery = useAvailability(property._id);

    const editDescription = () => {
        setEditingDescription(true);
        setFirstEditButton(true);
    };

    const refreshAll = { shouldRefreshAll, setRefreshAll };
    const canSetPropertyDescription = auth0User && model.memberWithIdHasClaims(auth0User.id, PropertyClaims.UpdateProperty);
    return (
        <CommonPropertyTab property={property}>
            <Box display='flex' alignItems='stretch' flexDirection={{ xs: 'column', sm: 'row' }} gap={2}>
                <Box flexGrow={1} display='flex' flexDirection='column' alignItems='flex-start'>
                    <Box display='flex' gap={1}>
                        <PropertyIcon property={property} dimensions={80} variant='outlined' />
                        <AddressDisplay property={property} />
                    </Box>
                    {canSetPropertyDescription && !firstEditButton && !model.description &&
                        <Button size='small' onClick={editDescription}>{t('properties.setDescription')}</Button>
                    }
                    <CurrentOccupants property={property} availabilityQuery={availabilityQuery} refreshAll={refreshAll} />
                    <SelfOccupant property={property} availabilityQuery={availabilityQuery} refreshAll={refreshAll} />
                </Box>
                {(model.description || firstEditButton) &&
                    <Box flexGrow={2}>
                        <Typography variant='h6'>{t('properties.notes')}</Typography>
                        <PropertyDescription
                            property={property}
                            canEdit={canSetPropertyDescription}
                            onEditingDone={() => setFirstEditButton(false)}
                            isEditingDescription={isEditingDescription}
                            setEditingDescription={setEditingDescription}
                        />
                    </Box>
                }
            </Box>
            <ReservationDetailDialog
                reservationDidUpdate={() => setRefreshAll(true)}
                reservationDidDelete={() => setRefreshAll(true)}
            />
        </CommonPropertyTab >
    );
};

export default OverviewPropertyTab;