import { Outlet } from 'react-router-dom';
import Container from '@mui/material/Container';

import AllocampHelmet from '../components/AllocampHelmet';

const Reservations = () => (
    <Container sx={{ py: 2 }}>
        <AllocampHelmet i18nKey='nav.reservations' />
        <Outlet />
    </Container>
);

export default Reservations;