import Box from '@mui/material/Box';
import TipsAndUpdatesIcon from '@mui/icons-material/TipsAndUpdates';
import { yellow } from '@mui/material/colors';
import Typography from '@mui/material/Typography';

import CommonPropertyTab from './CommonPropertyTab';

const ExpensesPropertyTab = ({ property }) => {
    return (
        <CommonPropertyTab property={property}>
            <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center' gap={2} sx={{ mt: 2 }}>
                <Typography variant='h5' align='center'>Coming soon!</Typography>
                <TipsAndUpdatesIcon sx={{ fontSize: 100, color: yellow[500] }} />
                <Typography variant='subtitle1' align='center'>Log property expenses and split costs with members</Typography>
            </Box>
        </CommonPropertyTab >
    );
};

export default ExpensesPropertyTab;