import { useState } from 'react';
import { useQuery, useMutation, gql } from '@apollo/client';
import dayjs from 'dayjs';

import { DATE_FORMAT } from '@allocamp/common';
import { ErrorModel } from '../models/error.model.mjs';
import { ReservationModel } from '../models/reservation.model.mjs';

const RESERVATION_FRAGMENT = `
    _id name startDate endDate
    user { _id email displayName picture }
    property {
        _id name icon color
    }
    editable { edit delete }
`;
const RESERVATION_QUERY = gql`
    query Reservations($first: Int, $after: String, $filter: ReservationFilter) {
        reservations(first: $first, after: $after, filter: $filter) {
            edges {
                cursor
                node {
                    ${RESERVATION_FRAGMENT}
                }
            }
            pageInfo {
                total startCursor endCursor hasPreviousPage hasNextPage
            }
        }
    }
`;
const RESERVATION_BY_ID = gql`
    query ReservationById($id: ID!) {
        reservationById(id: $id) { ${RESERVATION_FRAGMENT} }
    }
`;

const RESERVATION_ITERATOR = gql`
    query ReservationIterator($cursor: String) {
        reservationIterator(cursor: $cursor) {
            current {
                cursor
                node {
                    ${RESERVATION_FRAGMENT}
                }
            }
            prev {
                cursor
            }
            next {
                cursor
            }
        }
    }
`;

const CREATE_RESERVATION = gql`
    mutation CreateReservation($input: CreateReservationInput!) {
        createReservation(input: $input) {
            _id name startDate endDate
            property {
                name
            }
        }
    }
`;
const UPDATE_RESERVATION = gql`
    mutation UpdateReservation($id: ID!, $input: UpdateReservationInput!) {
        updateReservation(id: $id, input: $input) {
            ${RESERVATION_FRAGMENT}
        }
    }
`;
const DELETE_RESERVATION = gql`
    mutation DeleteReservation($id: ID!) {
        deleteReservation(id: $id)
    }
`;

const filterEquality = (prev, next) => (
    JSON.stringify(prev) === JSON.stringify(next)
);

export const useReservationById = (id) => {
    const { data, loading, error } = useQuery(RESERVATION_BY_ID, {
        skip: !id,
        variables: { id }
    });
    return {
        reservation: data?.reservationById ? new ReservationModel(data.reservationById) : null,
        loading,
        error
    };
};

export const useReservations = ({ filter: defaultFilter, limit }) => {
    const [filter, setFilter] = useState(defaultFilter);
    const { data, loading, error, fetchMore, refetch, networkStatus } = useQuery(RESERVATION_QUERY, {
        variables: {
            first: limit,
            filter
        },
        skip: !filter,
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true
    });

    const loadMore = () => {
        return fetchMore({
            query: RESERVATION_QUERY,
            notifyOnNetworkStatusChange: true,
            variables: {
                first: limit,
                after: data.reservations.pageInfo.endCursor,
                filter
            },
            updateQuery: (previousResult, { fetchMoreResult }) => {
                const newEdges = fetchMoreResult?.reservations.edges;
                const pageInfo = fetchMoreResult?.reservations.pageInfo;

                return fetchMoreResult && newEdges.length > 0
                    ? {
                        reservations: {
                            __typename: previousResult.reservations.__typename,
                            edges: [...previousResult.reservations.edges, ...newEdges],
                            pageInfo
                        }
                    }
                    : previousResult;
            }
        })
    };
    const setFilterAndRefetch = (newFilter) => {
        if (!filterEquality(filter, newFilter)) {
            setFilter(newFilter);
            refetch({ filter: newFilter });
        }
    };

    return {
        filter,
        reservations: data?.reservations.edges.map(({ node }) => node) ?? [],
        hasNextPage: data?.reservations.pageInfo.hasNextPage ?? false,
        count: data?.reservations.edges.length ?? 0,
        networkStatus,
        loading,
        error,

        setFilter: setFilterAndRefetch,
        refetch: () => refetch({ filter }),
        loadMore
    };
};

export const useMyNextReservation = (propertyId) => {
    const filter = {
        propertyIds: [propertyId],
        owner: 'self',
        startDate: dayjs().format(DATE_FORMAT)
    };
    const { reservations, count, loading, error } = useReservations({ filter, limit: 1 });

    return {
        reservation: count === 1 ? reservations[0] : null,
        model: count === 1 ? new ReservationModel(reservations[0]) : null,
        count,
        loading,
        error
    };
};

const AVAILABILITY_QUERY = gql`
    query AvailabilityForProperty($propertyId: ID!, $fromDate: Date!) {
        availabilityForProperty(propertyId: $propertyId, fromDate: $fromDate) {
            nextAvailableDate
            nextOccupiedDate
        }
    }
`;
export const useAvailability = (propertyId) => {
    const { data, loading, error, refetch } = useQuery(AVAILABILITY_QUERY, {
        variables: {
            propertyId,
            fromDate: dayjs().format(DATE_FORMAT)
        },
        fetchPolicy: 'cache-and-network'
    });

    return {
        availability: data?.availabilityForProperty,
        loading,
        error,
        refetch
    };
};

export const useCurrentOccupants = (propertyId) => {
    const today = dayjs().format(DATE_FORMAT);
    const filter = {
        propertyIds: [propertyId],
        startDate: today,
        endDate: today
    };
    return useReservations({ filter });
};

export const useReservationIterator = () => {
    const [cursor, setCursor] = useState(null);
    const { data, loading, error, refetch } = useQuery(RESERVATION_ITERATOR, {
        fetchPolicy: 'cache-and-network'
    });

    const current = data?.reservationIterator.current;
    const prev = data?.reservationIterator.prev;
    const next = data?.reservationIterator.next;

    const setCursorAndRefetch = (newCursor) => {
        if (cursor !== newCursor) {
            setCursor(newCursor);
            refetch({ cursor: newCursor });
        }
    };
    const goPrev = () => prev ? setCursorAndRefetch(prev.cursor) : null;
    const goNext = () => next ? setCursorAndRefetch(next.cursor) : null;

    return {
        current,
        hasPrev: !!prev,
        hasNext: !!next,
        goPrev,
        goNext,
        loading,
        error
    };
};

export const useCreateReservation = () => {
    const [createReservation, createReservationMutation] = useMutation(CREATE_RESERVATION);
    return {
        createReservation: (input, { onCompleted, onError } = {}) => createReservation({
            variables: { input },
            refetchQueries: ['Reservations'],
            onCompleted: data => onCompleted?.(new ReservationModel(data.createReservation)),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('createReservation', error)
        }),
        loading: createReservationMutation.loading
    }
};
export const useUpdateReservation = () => {
    const [updateReservation, updateReservationMutation] = useMutation(UPDATE_RESERVATION);
    return {
        updateReservation: (id, name, startDate, endDate, { onCompleted, onError } = {}) => updateReservation({
            variables: { id, input: { name, startDate, endDate } },
            refetchQueries: ['Reservations'],
            onCompleted: data => onCompleted?.(new ReservationModel(data.updateReservation)),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('updateReservation', error)
        }),
        loading: updateReservationMutation.loading
    };
};
export const useDeleteReservation = () => {
    const [deleteReservation, deleteReservationMutation] = useMutation(DELETE_RESERVATION);
    return {
        deleteReservation: (id, { onCompleted, onError } = {}) => deleteReservation({
            variables: { id },
            refetchQueries: ['Reservations'],
            onCompleted: data => onCompleted?.(data.deleteReservation),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('deleteReservation', error)
        }),
        loading: deleteReservationMutation.loading
    };
};