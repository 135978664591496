import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import UpcomingIcon from '@mui/icons-material/Upcoming';
import { withTranslation } from 'react-i18next';

import PropertyIcon from '../property/PropertyIcon';
import Error from '../components/Error';
import { ReservationModel } from '../models/reservation.model.mjs';
import { useReservationIterator } from '../reservation/useReservations';

const ReservationCard = ({ model }) => (
    <Box display='flex' gap={2}>
        {!model
            ? <Skeleton variant='rounded' width={60} height={60} />
            : <PropertyIcon property={model.property} variant='outlined' dimensions={60} />
        }
        <Box display='flex' flexGrow={1} flexDirection='column'>
            {!model
                ? <>
                    <Typography variant='body1'><Skeleton width={150} /></Typography>
                    <Typography variant='body2'><Skeleton width={150} /></Typography>
                </>
                : <>
                    <Typography variant='body1'>{model.dateRangeDisplay()}</Typography>
                    <Typography variant='body2'>{model.displayName()}</Typography>
                </>
            }
        </Box>
    </Box>
);
const NoUpcomingReservation = withTranslation()(({ t }) => (
    <Box display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
        <UpcomingIcon />
        <Typography variant='body1'>{t('reservations.noReservationsSelf')}</Typography>
    </Box>
));

const ReservationCarousel = () => {
    const { current, hasPrev, hasNext, goPrev, goNext, loading, error } = useReservationIterator();
    if (error) return <Error error={error} />;

    return (
        <Box display='flex' justifyContent='space-between'>
            <IconButton size='large' disabled={loading || !hasPrev} onClick={goPrev}>
                <ChevronLeftIcon fontSize='inherit' />
            </IconButton>
            {loading || current
                ? <ReservationCard model={loading ? null : new ReservationModel(current.node)} />
                : <NoUpcomingReservation />
            }
            <IconButton size='large' disabled={loading || !hasNext} onClick={goNext}>
                <ChevronRightIcon fontSize='inherit' />
            </IconButton>
        </Box>
    );
};

export default ReservationCarousel;