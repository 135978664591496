import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PropertyModel from '../models/property.model.mjs';

const CommonPropertyTab = ({ property, children }) => {
    const propertyModel = new PropertyModel(property);
    return (
        <>
            <Typography variant='h4' sx={{ pt: { xs: 1, sm: 0 } }}>{propertyModel?.name}</Typography>
            <Box sx={{ py: 1 }}>
                {children}
            </Box>
        </>
    );
};

export default CommonPropertyTab;