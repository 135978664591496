import { useState } from 'react';
import { Controller, useWatch } from 'react-hook-form';
import { styled } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Collapse from '@mui/material/Collapse';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';

import IconSelector from './IconSelector';
import ColorSelector from './ColorSelector';
import { colorFromName, CONTRAST_COLOR } from '../components/colors.js';
import PropertyIcon from './PropertyIcon';

const PREVIEW_DIMENSION = 80;

const ExpandMore = styled(({ expand, ...other }) => {
    return <ExpandMoreIcon {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
    marginLeft: 'auto',
    transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
    })
}));

const PropertyIconSelector = ({ control, disabled }) => {
    const { t } = useTranslation();
    const [expanded, setExpanded] = useState(false);
    const color = useWatch({ control, name: 'color' });
    const icon = useWatch({ control, name: 'icon' });

    return (
        <Box>
            <Box display='flex'>
                <Box display='flex' flexDirection='column'>
                    <Button onClick={() => setExpanded(toggle => !toggle)} disabled={disabled}>
                        <Box display='flex' alignItems='center'>
                            <PropertyIcon icon={icon} color={color} dimensions={PREVIEW_DIMENSION} variant='outlined' />
                            <Box sx={{ width: 10 }} />
                            <ExpandMore expand={expanded} />
                        </Box>
                    </Button>
                    <Typography variant='caption' color='text.secondary' sx={{ ml: 1 }}>{t('properties.labels.icon')}</Typography>
                </Box>
            </Box>
            <Collapse in={expanded && !disabled} timeout='auto'>
                <Divider sx={{ mt: 1, mb: 2 }} />
                <Box display='flex' alignItems='center' flexDirection={{ xs: 'column', md: 'row' }} gap={2}>
                    <Box>
                        <Controller name='color' control={control}
                            render={({ field }) => (
                                <ColorSelector
                                    selected={field.value}
                                    onSelected={field.onChange}
                                    dimensions={50}
                                />
                            )}
                        />
                    </Box>
                    <Box flexGrow={1}>
                        <List sx={{ maxHeight: 200, overflow: 'auto' }}>
                            <ListItem>
                                <Controller name='icon' control={control}
                                    render={({ field }) => (
                                        <IconSelector
                                            selected={field.value}
                                            onSelected={field.onChange}
                                            dimensions={60}
                                            selectedColor={colorFromName(color)}
                                            selectedContrastColor={CONTRAST_COLOR}
                                        />
                                    )}
                                />
                            </ListItem>
                        </List>
                    </Box>
                </Box>
            </Collapse>
        </Box>
    );
}

export default PropertyIconSelector;