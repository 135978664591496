import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const FullPageLoad = ({ label }) => (
    <Box display='flex' flexDirection='column' alignItems='center' gap={2}>
        <CircularProgress />
        <Typography variant='caption' color='text.secondary'>{label}</Typography>
    </Box>
);

export default FullPageLoad;