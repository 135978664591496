export const MAX_NAME_LENGTH = 50;
export const MAX_LONG_NAME_LENGTH = 100;
export const MAX_LONG_TEXT = 5000;
export const DATE_FORMAT = 'YYYY-MM-DD';

export const ACCOUNT_TYPE = Object.freeze({
    UTILITIES: 'utilities',
    FINANCIAL: 'financial',
    MAINTENANCE: 'maintenance',
    INSURANCE: 'insurance',
    NEIGHBOR: 'neighbor',
    OTHER: 'other'
});