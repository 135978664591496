import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import { useAuth0 } from '../auth/useAuth.js';

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  const { t } = useTranslation();
  return (
    <Button color='inherit' variant='text' size='small'
      onClick={loginWithRedirect}
    >{t('actions.login')}</Button>
  );
};

export default LoginButton;