import AbstractModel from './model.mjs';
import { PropertyUserRole } from '@allocamp/common';

export class MemberRoleModel extends AbstractModel {
    static i18nKeyPropertyRole(role) {
        return `role.${role}`;
    }

    get user() {
        return this.entity.user;
    }
    get userId() {
        return this.user._id;
    }
    get role() {
        return this.entity.role;
    }
    get claims() {
        return this.entity.claims;
    }
    get displayName() {
        return this.user.displayName;
    }
    get email() {
        return this.user.email;
    }
    get picture() {
        return this.user.picture;
    }

    hasClaims(...claims) {
        return claims.some(c => this.entity.claims.includes(c));
    }
    isAccountOwner() {
        return this.role === PropertyUserRole.AccountOwner;
    }
    i18nRoleKey() {
        return MemberRoleModel.i18nKeyPropertyRole(this.role);
    }
    canEditProperty(loggedInUserId) {
        return (this.userId !== loggedInUserId) && !this.isAccountOwner()
    }
}

export class InvitationModel extends AbstractModel {
    
    get recipient() {
        return this.entity.recipient;
    }
    get senderName() {
        return this.entity.sender.displayName;
    }
    get invitedAt() {
        return this.entity.invitedAt;
    }

    get property() {
        return this.entity.propertyInvite?.property;
    }
    get propertyName() {
        return this.property?.name;
    }
    get propertyId() {
        return this.property?._id;
    }
}

class PropertyModel extends AbstractModel {

    constructor(entity) {
        super(entity);
        this._members = entity.members?.map(m => new MemberRoleModel(m));
    }

    get name() {
        return this.entity.name;
    }
    get members() {
        return this.entity.members;
    }
    get memberModels() {
        // separate for backwards compatibility; TODO combine with members
        return this._members;
    }
    get description() {
        return this.entity.description;
    }
    get icon() {
        return this.entity.icon;
    }
    get color() {
        return this.entity.color;
    }
    get addressMailing() {
        return this.entity.addressMailing;
    }
    get addressBilling() {
        return this.entity.addressBilling;
    }

    memberCount() {
        return this.members.length;
    }
    hasMembers() {
        return this.memberCount() > 0
    }
    
    memberForId(id) {
        return id ? this.members.find(m => m.user._id === id) : null;
    }
    memberWithIdHasClaims(id, ...claims) {
        const member = this.memberForId(id);
        return member && new MemberRoleModel(member).hasClaims(...claims);
    }
}

export default PropertyModel;
export { PropertyUserRole, PropertyClaims } from '@allocamp/common';