import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CheckIcon from '@mui/icons-material/Check';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const EmailAddress = ({ email, size }) => {
    const { t } = useTranslation();
    const [showCopy, setShowCopy] = useState(true);

    useEffect(() => {
        if (showCopy) return;
        setTimeout(function () {
            setShowCopy(true);
        }, 3000);
    }, [showCopy, setShowCopy]);

    const onCopyClicked = () => {
        navigator.clipboard.writeText(email);
        setShowCopy(false);
    };

    return (
        <Box display='flex' alignItems='center' component='span'>
            <Typography variant='body2' align='left' color='text.secondary' component='span'>{email}</Typography>
            {showCopy &&
                <Tooltip title={t('actions.copy')} arrow>
                    <IconButton size={size} onClick={onCopyClicked}>
                        <ContentCopyIcon fontSize='inherit' />
                    </IconButton>
                </Tooltip>
            }
            {!showCopy &&
                <Box display='flex' alignItems='center' component='span' sx={{ ml: 1 }} gap={0.5}>
                    <CheckIcon color='success' sx={{ fontSize: 16 }} />
                    <Typography variant='caption' align='left' color='success.main' component='span' sx={{ mt: 0.25 }}>{t('copied')}</Typography>
                </Box>
            }
        </Box>
    );
};

export default EmailAddress;