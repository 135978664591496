import { useTranslation, withTranslation } from 'react-i18next';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
//import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';

import { useInvitations, useInvitationResponse } from './useInvitations.js';
import PropertyIcon from './PropertyIcon';
import { useSnackbarApolloCallback } from '../components/useSnackbar.js';

const InvitationCard = withTranslation()(({ property, title, body, onAccept, onDecline, t }) => (
    <Card sx={{ width: { xs: 1, sm: 275 }, maxWidth: { xs: 1, sm: 275 } }} variant='outlined'>
        <CardHeader
            title={
                <Typography variant='h6' component='div'>
                    {title}
                </Typography>
            }
        />
        {/* <CardMedia
                sx={{ height: 140 }}
                image='/static/images/cards/contemplative-reptile.jpg'
                title='green iguana'
            /> */}
        <PropertyIcon property={property} variant='none' square />
        <CardContent>
            <Typography variant='body2' color='text.secondary'>
                {body}
            </Typography>
        </CardContent>
        <CardActions>
            <Button size='small' onClick={onAccept}>{t('actions.accept')}</Button>
            <Button size='small' onClick={onDecline}>{t('actions.decline')}</Button>
        </CardActions>
    </Card>
));

const InviteList = () => {
    const { t } = useTranslation();
    const { callbackWithError } = useSnackbarApolloCallback();
    const { invitations } = useInvitations();
    const { invitationResponse } = useInvitationResponse();

    const handleResponse = async (invitationModel, response) => invitationResponse(invitationModel.id, response,
        {
            entity: invitationModel.entity,
            ...callbackWithError(t(response === 'accept'
                ? 'invitations.notification.acceptedInvite'
                : 'invitations.notification.declinedInvite', { property: invitationModel.propertyName })
            )
        }
    );

    const hasInvitations = invitations?.length > 0;
    return (hasInvitations &&
        <Box>
            <Typography variant='h5' sx={{ mb: 2 }}>{t('invitations.title')}</Typography>
            <Box display='flex' flexWrap='wrap' gap={2} flexDirection={{ xs: 'column', sm: 'row' }}>
                {invitations.map(model => (
                    <InvitationCard key={model.id}
                        property={model.property}
                        title={model.propertyName}
                        body={t('invitations.invitedBy', { sender: model.senderName, timeAgo: dayjs(model.invitedAt).fromNow() })}
                        onAccept={() => handleResponse(model, 'accept')}
                        onDecline={() => handleResponse(model, 'decline')}
                    />
                ))}
            </Box>
        </Box>
    );
};

export default InviteList;