import { useTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

import CommonPropertyTab from './CommonPropertyTab';
import PropertyForm from './PropertyForm';
import { PropertyClaims } from '../models/property.model.mjs';
import { routes } from '../routes.mjs';
import DangerZone from '../components/DangerZone';
import { useAuth0 } from '../auth/useAuth.js';
import { useDeleteProperty } from './useProperty.js';
import { useSnackbar } from '../components/useSnackbar.js';

const DeleteProperty = ({ propertyModel }) => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const { displaySuccess, displayError } = useSnackbar();

    const { deleteProperty, loading } = useDeleteProperty();
    const handleDelete = (closeDialog) => deleteProperty(propertyModel.id, {
        onError: (error) => {
            closeDialog();
            displayError(error.display());
        },
        onCompleted: () => {
            closeDialog();
            displaySuccess(t('properties.notification.deleteSuccess', { propertyName: propertyModel.name }));
            navigate(routes.properties.dashboard);
        }
    });

    const canDelete = propertyModel.memberCount() === 1;
    return (
        <Box>
            <Typography variant='h5' sx={{ mt: 2 }}>{ }</Typography>
            <Box sx={{ my: 1 }}>
                <DangerZone
                    sectionTitle={t('properties.deleteProperty')}
                    disabled={!canDelete}
                    loading={loading}
                    dialogContent={t('properties.deleteConfirmation')}
                    destructiveButtonText={t('actions.delete')}
                    notAvailableText={!canDelete ? t('properties.deleteInstructions') : null}
                    onDialogConfirm={handleDelete}
                >
                    <Trans i18nKey='properties.deleteWarning'>
                        <strong>{{ propertyName: propertyModel.name }}</strong>
                        <strong />
                    </Trans>
                </DangerZone>
            </Box>
        </Box>
    );
};

const SettingsPropertyTab = ({ property, model, refetch }) => {
    const { auth0User } = useAuth0();
    const hasUpdateClaim = auth0User && model.memberWithIdHasClaims(auth0User.id, PropertyClaims.UpdateProperty);
    const hasDeleteClaim = auth0User && model.memberWithIdHasClaims(auth0User.id, PropertyClaims.DeleteProperty);
    return (
        <CommonPropertyTab property={property}>
            <Stack gap={2}>
                {hasUpdateClaim && 
                    <PropertyForm property={property} onFormSubmitted={async () => await refetch()} />
                }
                {hasDeleteClaim && 
                    <DeleteProperty propertyModel={model} />
                }
            </Stack>
        </CommonPropertyTab>
    );
};

export default SettingsPropertyTab;