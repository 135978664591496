import { useQuery, useMutation, gql } from '@apollo/client';
import dayjs from 'dayjs';

import { InvitationModel } from '../models/property.model.mjs';
import { PROPERTY_QUERY_FRAGMENT } from './useProperty.js';
import { ErrorModel } from '../models/error.model.mjs';

const INVITE_TO_PROPERTY = gql`
    mutation InviteToProperty($input: InvitationInput!) {
        inviteToProperty(input: $input) {
            _id recipient invitedAt
            propertyInvite {
                property { _id }
            }
        }
    }
`;
const CANCEL_INVITATION = gql`
    mutation CancelInvitation($id: ID!) {
        cancelInvitation(invitationId: $id)
    }
`;

const INVITATIONS_FOR_SENDER = gql`
    query InvitationsForSender {
        invitationsForSender {
            _id recipient invitedAt
            propertyInvite {
                property { _id }
            }
        }
    }
`;

const CHANGE_MEMBER_ROLE = gql`
    mutation ChangeMemberRole($propertyId: ID!, $userId: ID!, $role: String!) {
        changeMemberRole(propertyId: $propertyId, userId: $userId, role: $role) {
            ${PROPERTY_QUERY_FRAGMENT}
        }
    }
`;
const REMOVE_MEMBER_FROM_PROPERTY = gql`
    mutation RemoveMemberFromProperty($propertyId: ID!, $userId: ID!) {
        removeMemberFromProperty(propertyId: $propertyId, userId: $userId) {
            ${PROPERTY_QUERY_FRAGMENT}
        }
    }
`;

const LEAVE_PROPERTY = gql`
    mutation LeaveProperty($id: ID!) {
        leaveProperty(id: $id) {
            ${PROPERTY_QUERY_FRAGMENT}
        }
    }
`;

export const useInviteToProperty = () => {
    const [inviteToProperty, inviteToPropertyMutation] = useMutation(INVITE_TO_PROPERTY, {
        refetchQueries: [{ query: INVITATIONS_FOR_SENDER }]
    });
    return {
        inviteToProperty: (recipient, propertyId, role, { onCompleted, onError } = {}) => inviteToProperty({
            variables: { input: { recipient, propertyId, role } },
            update: (store, context) => (
                store.updateQuery({ query: INVITATIONS_FOR_SENDER }, (result) => ({
                    invitationsForSender: [...result.invitationsForSender, context.data.inviteToProperty]
                }))
            ),
            optimisticResponse: {
                inviteToProperty: {
                    __typename: 'Invitation',
                    _id: dayjs().toISOString(),
                    recipient,
                    invitedAt: dayjs().toDate(),
                    propertyInvite: {
                        role,
                        property: { _id: propertyId }
                    }
                }
            },
            onCompleted: data => onCompleted?.(data.inviteToProperty),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('inviteToProperty', error)
        }),
        loading: inviteToPropertyMutation.loading
    };
};
export const useCancelInvitation = () => {
    const [cancelInvitation, cancelInvitationMutation] = useMutation(CANCEL_INVITATION, {
        refetchQueries: [{ query: INVITATIONS_FOR_SENDER }]
    });
    return {
        cancelInvitation: (id, { onCompleted, onError } = {}) => cancelInvitation({
            variables: { id },
            update: (store) => {
                // optimistic deletion
                store.modify({
                    fields: {
                        invitationsForSender(list, { readField }) {
                            return list.filter(ref => readField('_id', ref) !== id);
                        }
                    },
                    broadcast: false
                });
            },
            optimisticResponse: {
                cancelInvitation: true
            },
            onCompleted: data => onCompleted?.(data.cancelInvitation),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('cancelInvitation', error)
        }),
        loading: cancelInvitationMutation.loading
    }
};
export const useInvitationsForSender = (hasInviteClaim) => {
    const { data, error, loading } = useQuery(INVITATIONS_FOR_SENDER, { skip: !hasInviteClaim });
    return {
        senderInvitations: data?.invitationsForSender.map(inv => new InvitationModel(inv)),
        error,
        loading
    }
};
export const useChangeMemberRole = ({ refetchQuery, refetchVariables }) => {
    const [changeMemberRole, changeMemberRoleMutation] = useMutation(CHANGE_MEMBER_ROLE, {
        refetchQueries: [{ query: refetchQuery, variables: refetchVariables }]
    });
    return {
        changeMemberRole: (propertyId, userId, role, property, memberId, { onCompleted, onError } = {}) => changeMemberRole({
            variables: { propertyId, userId, role },
            update: (store, context) => (
                store.writeQuery({ query: refetchQuery, data: { propertyById: context.data.changeMemberRole }, variables: refetchVariables })
            ),
            optimisticResponse: {
                changeMemberRole: { ...property, members: property.members.map(m => m._id === memberId ? { ...m, role: role } : m) }
            },
            onCompleted: data => onCompleted?.(data.changeMemberRole),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('changeMemberRole', error)
        }),
        loading: changeMemberRoleMutation.loading
    };
};
export const useRemoveMemberFromProperty = ({ refetchQuery, refetchVariables }) => {
    const [removeMemberFromProperty, removeMemberFromPropertyMutation] = useMutation(REMOVE_MEMBER_FROM_PROPERTY, {
        refetchQueries: [{ query: refetchQuery, variables: refetchVariables }]
    });
    return {
        removeMemberFromProperty: (propertyId, userId, property, memberId, { onCompleted, onError } = {}) => removeMemberFromProperty({
            variables: { propertyId, userId },
            update: (store, context) => (
                // optimistic deletion
                store.modify({
                    id: store.identify(property),
                    fields: {
                        members() {
                            return context.data.removeMemberFromProperty.members;
                        }
                    },
                    broadcast: false
                })
            ),
            optimisticResponse: {
                removeMemberFromProperty: { ...property, members: property.members.filter(m => m._id !== memberId) }
            },
            onCompleted: data => onCompleted?.(data.removeMemberFromProperty),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('removeMemberFromProperty', error)
        }),
        loading: removeMemberFromPropertyMutation.loading
    }
};
export const useLeaveProperty = ({ refetchQuery, refetchVariables }) => {
    const [leaveProperty, leavePropertyMutation] = useMutation(LEAVE_PROPERTY, {
        refetchQueries: [{ query: refetchQuery, variables: refetchVariables }]
    });
    return {
        leaveProperty: (id, { onCompleted, onError } = {}) => leaveProperty({
            variables: { id },
            onCompleted: data => onCompleted?.(data.leaveProperty),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('leaveProperty', error)
        }),
        loading: leavePropertyMutation.loading
    }
};