export const _new = 'new';
export const exact = '';
export const home = 'home';
export const properties = 'properties';
export const profile = 'profile';
export const reservations = 'reservations';
export const accounts = 'accounts';
export const login = 'login';
export const logout = 'logout';

export const routes = {
    root: '/',
    home: `/${home}`,
    properties: {
        dashboard: `/${properties}`,
        create: `/${properties}/${_new}`,
        view: (id, tab) => `/${properties}/${id}${tab ? `?tab=${tab}` : ''}`
    },
    reservations: {
        dashboard: `/${reservations}`,
        new: () => `/${reservations}?expanded=true`
    },
    profile: `/${profile}`,
    logout: `/${logout}`,
    login: `/${login}`
};