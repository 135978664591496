import { useState, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Link as RouterLink } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Skeleton from '@mui/material/Skeleton';
import Link from '@mui/material/Link';
import Collapse from '@mui/material/Collapse';
import dayjs from 'dayjs';

import { DATE_FORMAT } from '@allocamp/common';
import { useCreateReservation } from './useReservations.js';
import EditReservation, { CreateReservationSchema } from './EditReservation.js';
import { useMe } from '../auth/useAuth.js';
import { routes } from '../routes.mjs';
import { useSnackbar } from '../components/useSnackbar.js';

const SKELETON_HEIGHT = 61;
const autoSelectPropertyId = (m) => m?.propertyCount() === 1 ? m.properties.at(0)._id : '';

const SkeletonForm = () => (
    <>
        <Skeleton height={SKELETON_HEIGHT} width={'100%'} />
        <Skeleton height={SKELETON_HEIGHT} width={'100%'} />
        <Box display='flex' gap={1}>
            <Skeleton height={SKELETON_HEIGHT} width={'100%'} />
            <Skeleton height={SKELETON_HEIGHT} width={'100%'} />
        </Box>
    </>
);

const ReservationForm = ({ property }) => {
    const [searchParams] = useSearchParams();
    const { t } = useTranslation();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));
    const [expanded, setExpanded] = useState(searchParams.get('expanded') === 'true' ?? false);
    const [isSafeToReset, setIsSafeToReset] = useState(false);
    const { displaySuccess, displayError } = useSnackbar();
    const { createReservation } = useCreateReservation();

    const formMethods = useForm({
        defaultValues: {
            name: '', startDate: '', endDate: '',
            propertyId: property?._id ?? ''
        },
        mode: 'all',
        resolver: yupResolver(CreateReservationSchema)
    });
    const { handleSubmit, reset, setValue, getValues, formState: { isValid, isSubmitting } } = formMethods;
    const { loading: meLoading, model } = useMe({
        onCompleted: ({ model: m }) => {
            // only automatically set the property if the user has a single property and it doesn't already have a value
            if (getValues('propertyId') === '') {
                setValue('propertyId', autoSelectPropertyId(m));
            }
        }
    });

    const onSubmit = async (data) => createReservation({
        ...data,
        startDate: dayjs(data.startDate).format(DATE_FORMAT),
        endDate: dayjs(data.endDate).format(DATE_FORMAT)
    }, {
        onError: (error) => displayError(error.display(t('reservations.genericError'))),
        onCompleted: (reservationModel) => {
            setIsSafeToReset(true);
            const name = reservationModel.displayName(true);
            return displaySuccess(t('reservations.createSuccess', { name }));
        }
    });

    // https://react-hook-form.com/api/useform/reset
    const onSubmitWrapper = async (data, e) => {
        setIsSafeToReset(false);
        await onSubmit(data, e);
    };
    useEffect(() => {
        if (!isSafeToReset) return;
        setIsSafeToReset(false);
        reset({
            name: '', startDate: '', endDate: '',
            propertyId: autoSelectPropertyId(model)
        });
    }, [reset, isSafeToReset, setIsSafeToReset, model]);

    return (
        <Box width={1}>
            {isSmall && !expanded &&
                <Button
                    variant='contained'
                    size='small'
                    onClick={() => setExpanded(val => !val)}
                >{t('home.newReservation')}</Button>
            }
            <Collapse in={!isSmall || expanded} timeout='auto'>
                <Typography variant='h5'>{t('reservations.newTitle')}</Typography>
                {meLoading
                    ? <SkeletonForm />
                    : (model?.hasProperties()
                        ? <form onSubmit={handleSubmit(onSubmitWrapper)}>
                            <EditReservation
                                formMethods={formMethods}
                                disabled={isSubmitting || meLoading}
                                properties={!meLoading && !property ? model?.properties : null}
                            />
                            <Box display='flex' justifyContent='space-between'>
                                <LoadingButton
                                    variant='contained'
                                    type='submit'
                                    disabled={!isValid || isSubmitting}
                                    loading={isSubmitting}
                                >{t('actions.save')}</LoadingButton>
                                {isSmall &&
                                    <Button
                                        variant='outlined'
                                        onClick={() => setExpanded(val => !val)}
                                    >{t('actions.close')}</Button>
                                }
                            </Box>
                        </form>
                        : <Box display='flex' flexDirection='column' gap={2}>
                            <Typography variant='body1' color='text.secondary'>{t('reservations.noProperties')}</Typography>
                            <Typography variant='body1' color='text.secondary'>
                                <Trans i18nKey='reservations.noPropertiesInstructions'>
                                    <Link component={RouterLink} to={routes.properties.create} />
                                    <Link component={RouterLink} to={routes.properties.dashboard} />
                                </Trans>
                            </Typography>
                        </Box>
                    )
                }
            </Collapse>
        </Box>
    );
};

export default ReservationForm;