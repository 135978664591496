import Stack from '@mui/material/Stack';

import ReservationForm from './ReservationForm';
import ReservationCalendar from './ReservationCalendar';
import ReservationDetailDialog from './ReservationDetailDialog.js';
import ReservationCreateDialog from './ReservationCreateDialog.js';

const ReservationManager = ({ property, heightOffset }) => (
    <>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5} justifyContent='space-between'>
            <ReservationForm property={property} />
            <ReservationCalendar property={property} heightOffset={heightOffset} />
        </Stack>
        <ReservationDetailDialog />
        <ReservationCreateDialog property={property} />
    </>
);

export default ReservationManager;