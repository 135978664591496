import i18n from '../i18n.js';

import { formatDateRange } from './reservation.model.mjs';

// TODO remove this function after refactor
export const snackbarMessage = (error, fallbackMessage) => new ErrorModel(error).display(fallbackMessage);

const formatter = (extensions) => ({
    ...extensions,
    dateRange: extensions.startDate && extensions.endDate 
        ? formatDateRange(extensions.startDate, extensions.endDate) 
        : undefined
});

// does not extend abstract model because it does not have a unique id
export class ErrorModel {

    constructor(error) {
        this.error = error;
        console.error('ErrorModel.new', error);
    }

    errors(index = 0) {
        return this.error.graphQLErrors?.at(index);
    }
    extensions(index = 0) {
        return this.errors(index)?.extensions;
    }
    message(index = 0) {
        return this.errors(index)?.message ?? i18n.t('error.unexpected');
    }
    code(index = 0) {
        return this.extensions(index)?.code;
    }
    i18nKey(index = 0) {
        return this.extensions(index)?.i18nKey;
    }

    display(fallbackMessage) {
        return i18n.t && this.i18nKey()
            ? i18n.t(this.i18nKey(), formatter(this.extensions())) 
            : fallbackMessage ?? this.message();
    }
};