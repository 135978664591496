import { useSnackbar as useSnackbarLib } from 'notistack';

export const useSnackbar = () => {
    const { enqueueSnackbar } = useSnackbarLib();
    return {
        displaySuccess: (message) => enqueueSnackbar(message, { variant: 'success' }),
        displayError: (message) => enqueueSnackbar(message, { variant: 'error' }),
    };
};

export const useSnackbarApolloCallback = () => {
    const { displaySuccess, displayError } = useSnackbar();

    const onCompletedWrapper = success => (
        data => typeof success === 'string'
            ? displaySuccess(success)
            : typeof success === 'function' ? displaySuccess(success(data)) : undefined
    );
    const onErrorWrapper = error => (
        data => typeof error === 'string'
            ? displayError(error)
            : typeof error === 'function' ? displayError(error(data)) : undefined
    );

    return {
        callback: (error, success) => ({
            onCompleted: onCompletedWrapper(success),
            onError: onErrorWrapper(error)
        }),
        callbackWithError: (success) => ({
            onCompleted: onCompletedWrapper(success),
            onError: error => displayError(error.display())
        })
    };
};