import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Tooltip from '@mui/material/Tooltip';
import Check from '@mui/icons-material/Check';

import { COLORS_MAP, CONTRAST_COLOR } from '../components/colors.js';

const COLOR_SHADE = 500;

const ColorSelector = ({ selected, onSelected, dimensions }) => {
    const cardDimensions = dimensions ?? 50;
    return (
        <Grid container spacing={0} columns={4} sx={{ width: cardDimensions * 4 }}>
            {Object.entries(COLORS_MAP).map(([key, color]) =>
                <Grid key={key} xs={1}>
                    <Card variant='none' square
                        onClick={() => onSelected(key)}
                        sx={{ background: color[COLOR_SHADE] }}
                    >
                        <Tooltip title={key} placement='right'>
                            <CardActionArea
                                sx={{
                                    width: cardDimensions, height: cardDimensions,
                                    border: selected === key ? `1px solid ${CONTRAST_COLOR}` : ''
                                }}
                            >
                                {selected === key &&
                                    <Box display='flex' justifyContent='center'>
                                        <Check sx={{ fontSize: cardDimensions * 0.6, color: CONTRAST_COLOR }} />
                                    </Box>
                                }
                            </CardActionArea>
                        </Tooltip>
                    </Card>
                </Grid>
            )}
        </Grid>
    );
};

export default ColorSelector;