import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';

import { iconFromName } from './IconSelector.js';
import { colorFromName, CONTRAST_COLOR } from '../components/colors.js';

const PropertyIcon = ({ iconSize, dimensions, property, icon, color, ...rest }) => {
    const resolveIcon = property?.icon ?? icon;
    const resolveColor = property?.color ?? color;

    const Icon = iconFromName(resolveIcon ?? 'Forest');
    const iconBgColor = colorFromName(resolveColor ?? 'lightGreen');
    const fontSize = iconSize ?? (dimensions ? dimensions * 0.6 : 100);
    return (
        <Paper sx={{ width: dimensions, height: dimensions, bgcolor: iconBgColor, color: CONTRAST_COLOR }} {...rest}>
            <Box 
                display='flex' 
                justifyContent='center'
                alignItems='center' 
                sx={{ width: dimensions, height: dimensions }}
            >
                <Icon sx={{ fontSize }} />
            </Box>
        </Paper>
    );
};

export default PropertyIcon;