import { useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { MAX_NAME_LENGTH } from '@allocamp/common';
import { yupResolver } from '@hookform/resolvers/yup';
import { useTranslation, Trans } from 'react-i18next';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Stack from '@mui/material/Stack';
import LoadingButton from '@mui/lab/LoadingButton';
import Skeleton from '@mui/material/Skeleton';
import * as yup from 'yup';

import ENV from '../env';
import i18n from '../i18n.js';
import AllocampHelmet from '../components/AllocampHelmet';
import { useMe, useResetPassword, useUpdateMe } from '../auth/useAuth.js';
import { routes } from '../routes.mjs';
import { useSnackbarApolloCallback } from '../components/useSnackbar.js';

const PROFILE_SCHEMA = yup.object({
    givenName: yup.string()
        .max(MAX_NAME_LENGTH, ({ max, value }) => i18n.t('validation.nameMaxLength', { maxLength: max, currentLength: value.length })),
    familyName: yup.string()
        .max(MAX_NAME_LENGTH, ({ max, value }) => i18n.t('validation.nameMaxLength', { maxLength: max, currentLength: value.length })),
    nickname: yup.string()
        .max(MAX_NAME_LENGTH, ({ max, value }) => i18n.t('validation.nameMaxLength', { maxLength: max, currentLength: value.length })),
}).required();

const REACTIVE_FORM = { my: 1, width: { xs: 1, sm: 500 } };
const SKELETON_HEIGHT = 61;
const SkeletonForm = () => (
    <Box display='flex' flexDirection='column' gap={1}>
        <Skeleton variant='circular'>
            <Avatar sx={{ width: 80, height: 80 }} />
        </Skeleton>
        <Box sx={REACTIVE_FORM}>
            <Typography>
                <Skeleton width={'100%'} />
            </Typography>
            <Stack spacing={1}>
                <Skeleton height={SKELETON_HEIGHT} width={'100%'} />
                <Skeleton height={SKELETON_HEIGHT} width={'100%'} />
                <Skeleton height={SKELETON_HEIGHT} width={'100%'} />
            </Stack>
        </Box>
    </Box>
);

const Profile = () => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { callbackWithError } = useSnackbarApolloCallback();
    const { model, loading: useMeLoading } = useMe();
    const [resetIsActive, setResetActive] = useState(false);

    const { control, handleSubmit, formState: { isValid } } = useForm({
        defaultValues: {
            givenName: '',
            familyName: '',
            nickname: ''
        },
        values: {
            givenName: model?.givenName ?? '',
            familyName: model?.familyName ?? '',
            nickname: model?.nickname ?? ''
        },
        mode: 'all',
        resolver: yupResolver(PROFILE_SCHEMA)
    });

    const { updateMe, loading: updateMeLoading } = useUpdateMe();
    const { resetPassword, loading: resetPasswordLoading } = useResetPassword();

    const onFormSubmit = async (input) => updateMe(
        input,
        callbackWithError(t('profile.notification.updateProfileSuccess'))
    );
    const onReset = async () => resetPassword(
        ENV.AUTH0.CLIENT_ID,
        {
            ...callbackWithError(),
            onCompleted: () => {
                setResetActive(true);
                navigate({
                    pathname: routes.logout,
                    search: '?type=resetpword',
                }, { state: { email: model.email } });
            }
        }
    );

    const formDisabled = useMeLoading || updateMeLoading || resetPasswordLoading;
    return (
        <Container sx={{ py: 2 }}>
            <AllocampHelmet i18nKey='nav.profile' />
            {!useMeLoading
                ? <Box display='flex' flexDirection='column' gap={1}>
                    <Avatar alt={model.displayName} src={model.picture} sx={{ width: 80, height: 80 }} />
                    <Typography>{model.email}</Typography>
                    <form onSubmit={handleSubmit(onFormSubmit)}>
                        <Box sx={REACTIVE_FORM}>
                            <Stack spacing={1}>
                                <Controller name='givenName' control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField {...field}
                                            disabled={formDisabled}
                                            label={t('profile.givenName')}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                                <Controller name='familyName' control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField {...field}
                                            disabled={formDisabled}
                                            label={t('profile.familyName')}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                                <Controller name='nickname' control={control}
                                    render={({ field, fieldState }) => (
                                        <TextField {...field}
                                            disabled={formDisabled}
                                            label={t('profile.nickname')}
                                            error={!!fieldState.error}
                                            helperText={fieldState.error?.message}
                                        />
                                    )}
                                />
                            </Stack>
                        </Box>
                        <LoadingButton
                            variant='contained'
                            type='submit'
                            disabled={!isValid || formDisabled}
                            loading={updateMeLoading}
                        >{t('actions.save')}</LoadingButton>
                    </form>
                </Box>
                : <SkeletonForm />
            }
            <Box display='flex' flexDirection='column' gap={1} sx={{ my: 3 }}>
                <Typography variant='h5'>{t('profile.title.changePassword')}</Typography>
                {useMeLoading
                    ? <Typography variant='body1' color='text.secondary'>
                        <Skeleton sx={{ width: { xs: '100%', sm: '60%' } }} />
                    </Typography>
                    : model.canResetPassword()
                        ? <Typography variant='body1' color='text.secondary'>
                            {!resetIsActive
                                ? <Trans i18nKey='profile.requestPasswordResetInstructions'>
                                    <strong />
                                </Trans>
                                : t('profile.requestPasswordResetActive')
                            }
                        </Typography>
                        : <Typography variant='body1' color='text.secondary'>{t('profile.invalidProvider')}</Typography>
                }
            </Box>
            <LoadingButton
                variant='contained'
                color='error'
                disabled={formDisabled || resetIsActive || !model?.canResetPassword()}
                loading={resetPasswordLoading}
                onClick={onReset}
            >{t('profile.requestPasswordReset')}</LoadingButton>
        </Container>
    );
};

export default Profile;