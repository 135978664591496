import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { useSearchParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import dayjs from 'dayjs';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';

import { DATE_FORMAT } from '@allocamp/common';
import { useMe } from '../auth/useAuth.js';
import { useCreateReservation } from './useReservations.js';
import EditReservation, { CreateReservationSchema } from './EditReservation.js';
import { useSnackbar } from '../components/useSnackbar.js';

const ReservationCreateDialog = ({ property }) => {
    const { t } = useTranslation();
    const [searchParams, setSearchParams] = useSearchParams();
    const { displaySuccess, displayError } = useSnackbar();
    const { createReservation } = useCreateReservation();
    const [propertyId, setPropertyId] = useState('');

    const dateRange = searchParams.get('rescreate');
    const [startDate, endDate] = dateRange?.split(',') ?? [null, null];

    const formMethods = useForm({
        defaultValues: { name: '', startDate: null, endDate: null, propertyId: null },
        mode: 'all',
        values: {
            name: '',
            propertyId: property?._id ?? propertyId,
            startDate: startDate ? dayjs(startDate) : '',
            endDate: endDate ? dayjs(endDate) : ''
        },
        resolver: yupResolver(CreateReservationSchema)
    });
    const { handleSubmit, formState: { isValid, isSubmitting } } = formMethods;
    const { loading: meLoading, model } = useMe({
        onCompleted: ({ model: m }) => {
            // only automatically set the property if the user has a single property and it doesn't already have a value
            if (m.propertyCount() === 1) {
                setPropertyId(m.properties.at(0)._id);
            }
        }
    });

    const closeDialog = () => {
        searchParams.delete('rescreate');
        setSearchParams(searchParams);
    };
    const onSubmit = async (data) => {
        closeDialog();
        return createReservation({
            ...data,
            startDate: dayjs(data.startDate).format(DATE_FORMAT),
            endDate: dayjs(data.endDate).format(DATE_FORMAT)
        }, {
            onError: (error) => displayError(error.display(t('reservations.genericError'))),
            onCompleted: (reservationModel) => {
                const name = reservationModel.displayName(true);
                displaySuccess(t('reservations.createSuccess', { name }));
            }
        });
    };

    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

    return (dateRange ?
        <Dialog open={!!dateRange} onClose={closeDialog} fullWidth={isSmall}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <DialogTitle>{t('reservations.newTitle')}</DialogTitle>
                <DialogContent>
                    <EditReservation
                        formMethods={formMethods}
                        disabled={isSubmitting}
                        readOnly={false}
                        properties={!meLoading ? model?.properties : []}
                    />
                </DialogContent>
                <Box display='flex' justifyContent='end' sx={{ mx: 2, mb: 1 }}>
                    <Box display='flex' gap={1}>
                        <Button
                            variant='contained'
                            type='submit'
                            disabled={!isValid || isSubmitting}
                        >{t('actions.save')}</Button>
                        {isSmall &&
                            <Button
                                variant='outlined'
                                onClick={closeDialog}
                            >{t('actions.close')}</Button>
                        }
                    </Box>
                </Box>
            </form>
        </Dialog>
        : null
    );
};

export default ReservationCreateDialog;