import Stack from '@mui/material/Stack';

import PropertyList from '../property/PropertyList';
import InviteList from '../property/InviteList.js';

const PropertyHome = () => (
    <Stack spacing={5}>
        <PropertyList />
        <InviteList />
    </Stack>
);

export default PropertyHome;