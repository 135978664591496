import { createElement } from 'react';
import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';

const TabPanel = (props) => {
    const { children, value, index } = props;
    return (
        <div
            role='tabpanel'
            hidden={value !== index}
            id={`${index}-tabpanel`}
            aria-labelledby={`${index}-tab`}
        >
            {value === index && (
                <Box sx={{ p: 2 }}>
                    {children}
                </Box>
            )}
        </div>
    );
};

const a11yProps = (name) => {
    return {
        id: `${name}-tab`,
        'aria-controls': `${name}-tabpanel`,
    };
};

const BasicTabs = ({ tabs, panelData }) => {
    const [searchParams, setSearchParams] = useSearchParams();
    const { t } = useTranslation();

    const handleChange = (_, newValue) => {
        searchParams.set('tab', tabs[newValue].slug);
        setSearchParams(searchParams);
    };

    const index = tabs.findIndex(elem => elem.slug === searchParams.get('tab'));
    const tabIndex = index >= 0 ? index : 0;
    return (
        <>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={tabIndex} onChange={handleChange} variant='scrollable' scrollButtons='auto' allowScrollButtonsMobile>
                    {tabs.map((val, idx) => <Tab key={idx} label={t(val.i18nKey)} {...a11yProps(idx)} />)}
                </Tabs>
            </Box>
            {tabs.map((val, idx) =>
                <TabPanel key={idx} value={tabIndex} index={idx}>
                    {createElement(val.component, panelData)}
                </TabPanel>)
            }
        </>
    );
};

export default BasicTabs;