import AbstractModel from './model.mjs';
import PropertyModel from './property.model.mjs';

export class AccountModel extends AbstractModel {
    constructor(entity) {
        super(entity);
        this.property = new PropertyModel(entity.property);
    }

    get name() {
        return this.entity.name;
    }
    get type() {
        return this.entity.type;
    }
    get typeI18nKey() {
        return `accounts.type.${this.type}`;
    }
    get accountNumber() {
        return this.entity.accountNumber;
    }
    get url() {
        return this.entity.url;
    }
    get urlTitle() {
        return this.entity.urlTitle;
    }
    get notes() {
        return this.entity.notes;
    }
    get propertyName() {
        return this.entity.property?.name;
    }
    get lastModified() {
        return this.entity.updatedAt;
    }

    // owner (relationship)
    get ownerId() {
        return this.entity.owner?._id;
    }
    get ownerDisplayName() {
        return this.entity.owner?.displayName;
    }
    get ownerEmail() {
        return this.entity.owner?.email;
    }
    get ownerPicture() {
        return this.entity.owner?.picture;
    }
    get ownerOverride() {
        return this.entity.ownerOverride;
    }

    // contact
    get contactName() {
        return this.entity.contactName;
    }
    get contactEmail() {
        return this.entity.contactEmail;
    }
    get contactPhone() {
        return this.entity.contactPhone;
    }
}