import * as yup from 'yup';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';
import { Controller, useWatch } from 'react-hook-form';
import { DateRangePicker as DateRangePickerPro } from '@mui/x-date-pickers-pro/DateRangePicker';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';

import './date-range-picker.css';

export const DateRangeSchema = yup.object({
    startDate: yup.date().required().test({
        test: (val, context) => !dayjs(context.parent.endDate).isValid() || dayjs(val).isSameOrBefore(dayjs(context.parent.endDate), 'day')
    }),
    endDate: yup.date().required()
}).required();

const DateRangePicker = ({ control, disabled, readOnly }) => {
    const { t } = useTranslation();
    const start = dayjs(useWatch({ control, name: 'startDate' }));
    const end = dayjs(useWatch({ control, name: 'endDate' }));

    const count = start.isValid() && end.isValid() ? end.diff(start, 'days') : null;
    return (
        <FormControl disabled={disabled}>
            <Controller name='startDate' control={control}
                render={({ field: startField }) => (
                    <Controller name='endDate' control={control}
                        render={({ field: endField }) => (
                            <DateRangePickerPro
                                disablePast={!readOnly}
                                disabled={startField.disabled || endField.disabled}
                                readOnly={readOnly}
                                value={[start, end]}
                                onChange={value => {
                                    if (!start?.isSame(value[0])) {
                                        startField.onChange(value[0]);
                                    }
                                    if (!end?.isSame(value[1])) {
                                        endField.onChange(value[1]);
                                    }
                                }}
                            />
                        )}
                    />
                )}
            />
            <FormHelperText>
                {count != null ? t('reservations.duration', { count }) : null}
            </FormHelperText>
        </FormControl>
    );
};

export default DateRangePicker;