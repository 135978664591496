import dayjs from 'dayjs';

import AbstractModel from './model.mjs';
import PropertyModel from './property.model.mjs';
import { routes } from '../routes.mjs';

export const eventModelFactory = (event) => {
    switch (event.eventName) {
        case 'res':
            return new ReservationEventModel(event);
        case 'acct':
            return new AccountEventModel(event);
        case 'inv':
            return new InvitationEventModel(event);
        default:
            return null;
    }
};

class EventModel extends AbstractModel {

    constructor(entity) {
        super(entity);
        this.property = new PropertyModel(entity.property);
    }

    get eventName() {
        return this.entity.eventName;
    }
    get eventAction() {
        return this.entity.eventAction;
    }
    get createdAt() {
        return this.entity.createdAt;
    }

    get userPicture() {
        return this.entity.user.picture;
    }
    get userDisplayName() {
        return this.entity.user.displayName;
    }
    get userEmail() {
        return this.entity.user.email;
    }

    description(t) {
        return t(this._i18nKey(), { property: this.property.name, ...this.entity.parameters });
    }

    _i18nKey() {
        return `events.${this.eventName}.${this.eventAction}`;
    }
}

class ReservationEventModel extends EventModel {

    details() {
        const current = this.entity.parameters;
        if (this.eventAction !== 'updated') {
            return this._dateRangeDisplay(current.startDate, current.endDate);
        } else {
            const original = this.entity.parameters.original;
            return {
                original: this._dateRangeDisplay(original.startDate, original.endDate),
                current: this._dateRangeDisplay(current.startDate, current.endDate)
            };
        }
    }

    link(t) {
        return {
            display: t('events.res.link'),
            path: routes.reservations.dashboard
        };
    }

    _dateRangeDisplay(startDate, endDate) {
        const start = dayjs(startDate);
        const end = dayjs(endDate);

        if (start.isSame(end, 'day')) {
            return start.format('MMM D, YYYY');
        } else {
            const startFormat = `MMM D${start.isSame(end, 'year') ? '' : ', YYYY'}`;
            const endFormat = `${start.isSame(end, 'month') ? '' : 'MMM '}D, YYYY`;
            return `${start.format(startFormat)} - ${end.format(endFormat)}`;
        }
    }
}

class AccountEventModel extends EventModel {
    link(t) {
        return {
            display: t('events.acct.link'),
            path: routes.properties.view(this.entity.property?._id, 'accounts')
        };
    }

    details(t) {
        if (this.eventAction === 'created') {
            return t('events.acct.nameFormat', { ...this.entity.parameters, type: t(`accounts.type.${this.entity.parameters.type}`) });
        }
        else if (this.eventAction === 'updated') {
            const fields = this.entity.parameters.diffs.map(d => t(`events.acct.field.${d}`)).join(', ');
            return {
                current: t('events.acct.updatedFormat', { fields })
            };
        }
        return this.entity.parameters.name;
    }
}

class InvitationEventModel extends EventModel {
    link(t) {
        return {
            display: t('events.inv.link'),
            path: routes.properties.view(this.entity.property?._id, 'membership')
        };
    }

    details(t) {
        return t('events.inv.createdFormat', { ...this.entity.parameters });
    }
}