import { ApolloClient, ApolloProvider, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';

import ENV from '../env';
import { useAuth0 } from './useAuth.js';

// https://nextsteps.dev/apollo-client-graphQL-and-auth
const AuthorizedApolloProvider = ({ children }) => {
    const { getAccessTokenSilently } = useAuth0();

    const httpLink = createHttpLink({
        uri: '/api/graphql'
    });

    const authLink = setContext(async (_, { headers, ...context }) => {
        const token = await getAccessTokenSilently({ audience: ENV.AUTH0.AUDIENCE });

        return {
            headers: {
                ...headers,
                authorization: token ? `Bearer ${token}` : ''
            },
            ...context
        };
    });

    const apolloClient = new ApolloClient({
        connectToDevTools: ENV.APOLLO.DEVTOOLS,
        link: authLink.concat(httpLink),
        cache: new InMemoryCache()
    });

    return (
        <ApolloProvider client={apolloClient}>
            {children}
        </ApolloProvider>
    );
};

export default AuthorizedApolloProvider;