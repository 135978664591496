import { HelmetProvider } from 'react-helmet-async';
import { Outlet, useLocation } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import { useTranslation } from 'react-i18next';
import Container from '@mui/material/Container';
import LoadingButton from '@mui/lab/LoadingButton';
import Alert from '@mui/material/Alert';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import MarkEmailReadIcon from '@mui/icons-material/MarkEmailRead';

import { Auth0ProviderWithHistory } from '../auth/Auth0Components';
import AuthorizedApolloProvider from '../auth/AuthorizedApolloProvider';
import AllocampHelmet from './AllocampHelmet';
import NavigationBar from './NavigationBar';
import { routes } from '../routes.mjs';
import { useAuth0, useVerificationEmail } from '../auth/useAuth.js';
import { useSnackbarApolloCallback } from './useSnackbar.js';

const EXEMPT_PATHS = Object.freeze([
    routes.profile,
    routes.logout
]);
const isExemptRoute = (pathname) => pathname === routes.root || EXEMPT_PATHS.some(r => pathname.startsWith(r));

const VerifyEmailWall = () => {
    const location = useLocation();
    const { auth0User } = useAuth0();
    const { t } = useTranslation();

    const { callbackWithError } = useSnackbarApolloCallback();
    const { resendVerificationEmail, loading } = useVerificationEmail();

    const handleResend = () => resendVerificationEmail(
        callbackWithError(t('home.resendSuccess', { email: auth0User?.email }))
    );

    return (auth0User && !auth0User?.emailVerified && !isExemptRoute(location.pathname)
        ? <Container>
            <Box display='flex' alignItems='center' sx={{ my: 3 }} flexDirection='column' gap={2}>
                <MarkEmailReadIcon sx={{ fontSize: 75 }} />
                <Typography align='center' variant='h4'>{t('home.verifyTitle')}</Typography>
                <Alert variant='outlined' severity='warning' sx={{ maxWidth: { xs: 1, sm: '50%' } }}>
                    <Typography variant='body1'>{t('home.empty.nonverifiedDescription')}</Typography>
                    <Typography variant='body1' sx={{ mt: 1 }}>{t('home.empty.nonVerifiedRefresh')}</Typography>
                    <Box display='flex' justifyContent='center' sx={{ mt: 2 }}>
                        <LoadingButton
                            loading={loading}
                            onClick={handleResend}
                        >{t('actions.resendVerification')}</LoadingButton>
                    </Box>
                </Alert>
            </Box>
        </Container>
        : <Outlet />
    );
};

const Root = () => {
    return (
        <Auth0ProviderWithHistory>
            <AuthorizedApolloProvider>
                <HelmetProvider>
                    <AllocampHelmet />
                    <NavigationBar />
                    <SnackbarProvider>
                        <VerifyEmailWall />
                    </SnackbarProvider>
                </HelmetProvider>
            </AuthorizedApolloProvider>
        </Auth0ProviderWithHistory>
    );
};

export default Root;