import { useQuery, useMutation, gql } from '@apollo/client';

import { AccountModel } from '../models/account.model.mjs';
import { ErrorModel } from '../models/error.model.mjs';

const ACCOUNT_FRAGMENT = `
_id createdAt updatedAt
name type
contactName contactEmail contactPhone
accountNumber url notes
ownerOverride

property {
    _id name
}
owner {
    _id displayName email picture
}
`;
const ALL_ACCOUNTS = gql`
    query Accounts($propertyId: ID!) {
        accounts(propertyId: $propertyId) { ${ACCOUNT_FRAGMENT} }
    }
`;
const ACCOUNT_BY_ID = gql`
    query AccountById($id: ID!) {
        accountById(id: $id) { urlTitle ${ACCOUNT_FRAGMENT} }
    }
`;

const CREATE_ACCOUNT = gql`
    mutation CreateAccount($input: CreateAccountInput!) {
        createAccount(input: $input) {
            ${ACCOUNT_FRAGMENT}
        }
    }
`;
const UPDATE_ACCOUNT = gql`
    mutation UpdateAccount($id: ID!, $input: UpdateAccountInput!) {
        updateAccount(id: $id, input: $input) {
            ${ACCOUNT_FRAGMENT}
        }
    }
`;
const DELETE_ACCOUNT = gql`
    mutation DeleteAccount($id: ID!) {
        deleteAccount(id: $id)
    }
`;

export const useAccounts = (propertyId) => {
    const { data, loading, error } = useQuery(ALL_ACCOUNTS, {
        variables: { propertyId }
    });
    return {
        accounts: data?.accounts.map(i => new AccountModel(i)) ?? [],
        loading,
        error
    };
};

export const useAccountById = (id) => {
    const { data, loading, error } = useQuery(ACCOUNT_BY_ID, {
        variables: { id },
        fetchPolicy: 'cache-and-network'
    });
    return {
        account: data?.accountById ? new AccountModel(data.accountById) : null,
        loading,
        error
    };
};

export const useCreateAccount = () => {
    const [createAccount, createAccountMutation] = useMutation(CREATE_ACCOUNT);
    return {
        createAccount: (input, { onCompleted, onError } = {}) => createAccount({
            variables: { input },
            refetchQueries: ['Accounts'],
            onCompleted: data => onCompleted?.(new AccountModel(data.createAccount)),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('createAccount', error)
        }),
        loading: createAccountMutation.loading
    };
};
export const useUpdateAccount = () => {
    const [updateAccount, updateAccountMutation] = useMutation(UPDATE_ACCOUNT);
    return {
        updateAccount: (id, input, { onCompleted, onError } = {}) => updateAccount({
            variables: { id, input },
            onCompleted: data => onCompleted?.(new AccountModel(data.updateAccount)),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('updateAccount', error)
        }),
        loading: updateAccountMutation.loading
    };
};
export const useDeleteAccount = () => {
    const [deleteAccount, deleteAccountMutation] = useMutation(DELETE_ACCOUNT);
    return {
        deleteAccount: (id, { onCompleted, onError } = {}) => deleteAccount({
            variables: { id },
            refetchQueries: ['Accounts'],
            onCompleted: data => onCompleted?.(data.deleteAccount),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('deleteAccount', error)
        }),
        loading: deleteAccountMutation.loading
    };
};