export const AllocampRole = Object.freeze({
    // internal administrator: full access
    Admin: 'allocamp.admin',
    // internal operator: cannot modify user data except billing
    Operator: 'allocamp.operator',
    // internal readonly
    Readonly: 'allocamp.readonly',
    // external member
    Member: 'allocamp.member'
});
export const GlobalClaims = Object.freeze({
    CreateProperty: 'property.create',
    CreateMembershipType: 'membershiptype.create',
    QueryMembershipType: 'membershiptype.query',
    UpdateGlobalRole: 'globalrole.update',
    InviteMember: 'invitemember.create',
    CreateReservation: 'reservation.create',
    CreateAccount: 'account.create'
});

export const PropertyUserRole = Object.freeze({
    // exactly one accountowner per property is required
    AccountOwner: 'property.accountowner',
    Owner: 'property.owner',
    Member: 'property.member'
});
export const PropertyClaims = Object.freeze({
    InviteMember: 'property.invitemember',
    RemoveMember: 'property.removemember',
    UpdateProperty: 'property.update',
    DeleteProperty: 'property.delete',
    ChangeMemberRole: 'property.changememberrole',
    CreateReservation: 'reservation.create',
    LeaveProperty: 'property.leave',
    ManageReservation: 'reservation.manage',
    CreateAccount: 'account.create',
    UpdateAccount: 'account.update',
    DeleteAccount: 'account.delete'
});