import { Link as RouterLink } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import { useTranslation } from 'react-i18next';

import ReservationCarousel from '../reservation/ReservationCarousel';
import { routes } from '../routes.mjs';

const MyNextReservation = () => {
    const { t } = useTranslation();
    return (
        <Box>
            <Typography variant='body1'>{t('home.myReservations')}</Typography>
            <Box sx={{ my: 2 }} display='flex' flexDirection='column' gap={3}>
                <ReservationCarousel />
                <Box>
                    <Button
                        component={RouterLink}
                        to={routes.reservations.new()}
                        variant='contained'
                        size='small'
                    >{t('home.newReservation')}</Button>
                </Box>
            </Box>
        </Box>
    );
};

const Dashboard = () => {
    const { t } = useTranslation();
    return (
        <Box width={1}>
            <Typography variant='h5'>{t('home.dashboard')}</Typography>
            <Stack spacing={5}>
                <MyNextReservation />
            </Stack>
        </Box>
    );
};

export default Dashboard;