import { useQuery, useMutation, gql } from '@apollo/client';

import { USEME_QUERY_NAME } from '../auth/useAuth';
import { ErrorModel } from '../models/error.model.mjs';
import { InvitationModel } from '../models/property.model.mjs';

const INVITATIONS_QUERY_NAME = 'Invitations';
const INVITATIONS_FOR_RECIPIENT = gql`
    query ${INVITATIONS_QUERY_NAME} {
        invitations {
            _id invitedAt
            propertyInvite {
                property { _id name icon color }
            }
            sender {
                displayName
            }
        }
    }
`;
const INVITATION_RESPONSE = gql`
    mutation InvitationResponse($id: ID!, $response: String!) {
        invitationResponse(invitationId: $id, response: $response) {
            _id invitedAt
            propertyInvite {
                property { _id name icon color }
            }
            sender {
                displayName
            }
        }
    }
`;

export const useInvitations = () => {
    const { data, error, loading } = useQuery(INVITATIONS_FOR_RECIPIENT, {
        fetchPolicy: 'cache-and-network'
    });
    return {
        invitations: data?.invitations.map(inv => new InvitationModel(inv)),
        error,
        loading
    };
};

export const useInvitationResponse = () => {
    const [invitationResponse, invitationResponseMutation] = useMutation(INVITATION_RESPONSE, {
        refetchQueries: [USEME_QUERY_NAME, INVITATIONS_QUERY_NAME]
    });

    return {
        invitationResponse: (id, response, { onCompleted, onError, entity } = {}) => invitationResponse({
            variables: { id, response },
            update: (store) => (
                // optimistic deletion
                store.modify({
                    fields: {
                        invitations(list, { readField }) {
                            return list.filter(ref => readField('_id', ref) !== id);
                        }
                    },
                    broadcast: false
                })
            ),
            optimisticResponse: {
                invitationResponse: {
                    ...entity,
                    invitationStatus: response === 'accept' ? 'accepted' : 'declined'
                }
            },
            onCompleted: data => onCompleted?.(data.invitationResponse),
            onError: error => onError?.(new ErrorModel(error)) ?? console.error('invitationResponse', error)
        }),
        loading: invitationResponseMutation.loading
    }
};