import Grid from '@mui/material/Unstable_Grid2';
import Card from '@mui/material/Card';
import Box from '@mui/material/Box';
import CardActionArea from '@mui/material/CardActionArea';
import Tooltip from '@mui/material/Tooltip';

import AcUnit from '@mui/icons-material/AcUnit';
import Balcony from '@mui/icons-material/Balcony';
import BeachAccess from '@mui/icons-material/BeachAccess';
import Casino from '@mui/icons-material/Casino';
import Castle from '@mui/icons-material/Castle';
import Cottage from '@mui/icons-material/Cottage';
import Deck from '@mui/icons-material/Deck';
import DirectionsBike from '@mui/icons-material/DirectionsBike';
import DirectionsBoat from '@mui/icons-material/DirectionsBoat';
import DownhillSkiing from '@mui/icons-material/DownhillSkiing';
import Fence from '@mui/icons-material/Fence';
import Festival from '@mui/icons-material/Festival';
import Fireplace from '@mui/icons-material/Fireplace';
import Flight from '@mui/icons-material/Flight';
import Forest from '@mui/icons-material/Forest';
import Fort from '@mui/icons-material/Fort';
import Foundation from '@mui/icons-material/Foundation';
import Gite from '@mui/icons-material/Gite';
import GolfCourse from '@mui/icons-material/GolfCourse';
import Grass from '@mui/icons-material/Grass';
import Hiking from '@mui/icons-material/Hiking';
import HolidayVillage from '@mui/icons-material/HolidayVillage';
import Home from '@mui/icons-material/Home';
import HotTub from '@mui/icons-material/HotTub';
import House from '@mui/icons-material/House';
import HouseSiding from '@mui/icons-material/HouseSiding';
import Houseboat from '@mui/icons-material/Houseboat';
import IceSkating from '@mui/icons-material/IceSkating';
import Icecream from '@mui/icons-material/Icecream';
import Kayaking from '@mui/icons-material/Kayaking';
import Kitesurfing from '@mui/icons-material/Kitesurfing';
import Landscape from '@mui/icons-material/Landscape';
import Liquor from '@mui/icons-material/Liquor';
import LocalFlorist from '@mui/icons-material/LocalFlorist';
import LocationCity from '@mui/icons-material/LocationCity';
import Luggage from '@mui/icons-material/Luggage';
import NaturePeople from '@mui/icons-material/NaturePeople';
import NightShelter from '@mui/icons-material/NightShelter';
import Nightlife from '@mui/icons-material/Nightlife';
import NordicWalking from '@mui/icons-material/NordicWalking';
import OutdoorGrill from '@mui/icons-material/OutdoorGrill';
import Panorama from '@mui/icons-material/Panorama';
import Park from '@mui/icons-material/Park';
import PedalBike from '@mui/icons-material/PedalBike';
import Pool from '@mui/icons-material/Pool';
import Rowing from '@mui/icons-material/Rowing';
import Sailing from '@mui/icons-material/Sailing';
import ScubaDiving from '@mui/icons-material/ScubaDiving';
import Signpost from '@mui/icons-material/Signpost';
import Skateboarding from '@mui/icons-material/Skateboarding';
import Sledding from '@mui/icons-material/Sledding';
import Snowboarding from '@mui/icons-material/Snowboarding';
import Snowmobile from '@mui/icons-material/Snowmobile';
import Snowshoeing from '@mui/icons-material/Snowshoeing';
import Surfing from '@mui/icons-material/Surfing';
import Villa from '@mui/icons-material/Villa';
import Water from '@mui/icons-material/Water';
import WbSunny from '@mui/icons-material/WbSunny';
import WbTwilight from '@mui/icons-material/WbTwilight';
import Weekend from '@mui/icons-material/Weekend';
import WineBar from '@mui/icons-material/WineBar';
import Yard from '@mui/icons-material/Yard';

const ICON_MAP = Object.freeze({
    AcUnit, Balcony, BeachAccess, Casino, Castle, Cottage, Deck, DirectionsBike, DirectionsBoat,
    DownhillSkiing, Fence, Festival, Fireplace, Flight, Forest, Fort, Foundation, Gite, GolfCourse,
    Grass, Hiking, HolidayVillage, Home, HotTub, House, HouseSiding, Houseboat, IceSkating, Icecream,
    Kayaking, Kitesurfing, Landscape, Liquor, LocalFlorist, LocationCity, Luggage, NaturePeople,
    NightShelter, Nightlife, NordicWalking, OutdoorGrill, Panorama, Park, PedalBike, Pool, Rowing,
    Sailing, ScubaDiving, Signpost, Skateboarding, Sledding, Snowboarding, Snowmobile, Snowshoeing,
    Surfing, Villa, Water, WbSunny, WbTwilight, Weekend, WineBar, Yard
});

export const iconFromName = name => ICON_MAP[name];

const IconSelector = ({ selected, onSelected, selectedColor, selectedContrastColor, dimensions }) => {

    const bgSelected = selectedColor ?? 'primary.main';
    const fgSelectedContrast = selectedContrastColor ?? 'primary.contrastText';
    const cardDimensions = dimensions ?? 75;
    return (
        <Grid container spacing={1}>
            {Object.entries(ICON_MAP).map(([key, Icon]) =>
                <Grid key={key}>
                    <Card sx={{ backgroundColor: key === selected ? bgSelected : '' }} onClick={() => onSelected(key)}>
                        <CardActionArea sx={{
                            width: cardDimensions, height: cardDimensions
                        }}>
                            <Box display='flex' justifyContent='center'>
                                <Tooltip title={key} placement='right'>
                                    <Icon sx={{ fontSize: cardDimensions * 0.6, color: key === selected ? fgSelectedContrast : '' }} />
                                </Tooltip>
                            </Box>
                        </CardActionArea>
                    </Card>
                </Grid>
            )}
        </Grid>
    );
};

export default IconSelector;