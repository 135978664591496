import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';

import AllocampHelmet from '../components/AllocampHelmet';
import Dashboard from './Dashboard';
import ActivityList from './ActivityList';

const Home = () => (
    <Container sx={{ py: 2 }}>
        <AllocampHelmet i18nKey='nav.home' />
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={5} justifyContent='space-between'>
            <Dashboard />
            <ActivityList />
        </Stack>
    </Container>
);

export default Home;