import Typography from '@mui/material/Typography';
import { withTranslation } from 'react-i18next';

const Error = ({ t, error }) => {
    if (error) console.error('unexpected error', error);
    return (
        <Typography variant='body1' color='error.main'>{t('error.unknown')}</Typography>
    );
};

export default withTranslation()(Error);