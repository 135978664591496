import { useState, useEffect } from 'react';
import { useTranslation, withTranslation, Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Divider from '@mui/material/Divider';
import ListItemText from '@mui/material/ListItemText';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import Avatar from '@mui/material/Avatar';
import Chip from '@mui/material/Chip';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import EditIcon from '@mui/icons-material/Edit';
import PersonRemove from '@mui/icons-material/PersonRemove';
import DeleteIcon from '@mui/icons-material/Delete';
import Box from '@mui/material/Box';
import { yupResolver } from '@hookform/resolvers/yup';
import { MAX_NAME_LENGTH } from '@allocamp/common';
import * as yup from 'yup';
import { useForm, Controller } from 'react-hook-form';
import dayjs from 'dayjs';

import EmailAddress from '../components/EmailAddress.js';
import i18n from '../i18n.js'
import CommonPropertyTab from './CommonPropertyTab';
import { MemberRoleModel, PropertyUserRole, PropertyClaims } from '../models/property.model.mjs';
import DangerZone from '../components/DangerZone.js';
import { routes } from '../routes.mjs';
import { useAuth0 } from '../auth/useAuth.js';
import { useSnackbar, useSnackbarApolloCallback } from '../components/useSnackbar.js';
import {
    useInviteToProperty,
    useCancelInvitation,
    useInvitationsForSender,
    useChangeMemberRole,
    useRemoveMemberFromProperty,
    useLeaveProperty
} from './useMembership.js';

const MemberItemPrimary = withTranslation()(({ member, t }) => (
    <>
        {member.displayName}
        <Chip label={t(member.i18nRoleKey())} size='small' sx={{ mx: 1 }}
            color={member.isAccountOwner() ? 'success' : undefined}
            variant='outlined'
        />
    </>
));
const MemberItem = ({ member, divider, canEdit, onEditMember }) => (
    <>
        <ListItem alignItems='flex-start' disableGutters
            secondaryAction={canEdit &&
                <IconButton edge='end' onClick={() => onEditMember(member)}>
                    <EditIcon />
                </IconButton>
            }
        >
            <ListItemAvatar>
                <Avatar alt={member.displayName} src={member.picture} />
            </ListItemAvatar>
            <ListItemText
                primary={<MemberItemPrimary member={member} />}
                secondary={<EmailAddress email={member.email} size='small' />}
            />
        </ListItem>
        {divider && <Divider component='li' />}
    </>
);
const InvitationItem = withTranslation()(({ invitation: invitationModel, divider, onDelete, t }) => (
    <>
        <ListItem alignItems='flex-start' disableGutters
            secondaryAction={
                <Tooltip title={t('invitations.cancel')} arrow>
                    <IconButton edge='end' onClick={() => onDelete(invitationModel)}>
                        <DeleteIcon color='error' />
                    </IconButton>
                </Tooltip>
            }
        >
            <ListItemText
                primary={invitationModel.recipient}
                secondary={t('invitations.invitedDescription', { timeAgo: dayjs(invitationModel.invitedAt).fromNow() })}
            />
        </ListItem>
        {divider && <Divider component='li' />}
    </>
));

const AVAILABLE_ROLES = [PropertyUserRole.Member, PropertyUserRole.Owner];
const inviteSchema = yup.object({
    email: yup.string()
        .email(() => i18n.t('properties.validation.invalidEmail'))
        .required(() => i18n.t('properties.validation.emailRequired'))
        .max(MAX_NAME_LENGTH, ({ max, value }) => i18n.t('validation.nameMaxLength', { maxLength: max, currentLength: value.length })),
    propertyRole: yup.string().oneOf(AVAILABLE_ROLES).required()
}).required();
const InviteDialog = ({ propertyName, open, onCancel, onSubmit }) => {
    const { t } = useTranslation();
    const [isSafeToReset, setIsSafeToReset] = useState(false);

    const { control, reset, handleSubmit, formState: { isValid } } = useForm({
        defaultValues: {
            email: '',
            propertyRole: PropertyUserRole.Member
        },
        resolver: yupResolver(inviteSchema)
    });

    // https://react-hook-form.com/api/useform/reset
    const onSubmitWrapper = async (data, e) => {
        setIsSafeToReset(false);
        await onSubmit(data, e);
        setIsSafeToReset(true);
    };
    useEffect(() => {
        if (!isSafeToReset) return;
        reset();
    }, [reset, isSafeToReset]);

    return (
        <Dialog open={open} onClose={onCancel}>
            <form onSubmit={handleSubmit(onSubmitWrapper)}>
                <DialogTitle>{t('actions.invite')}</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        <Trans i18nKey='invitations.inviteDialog.instructions'>
                            <strong>{{ propertyName }}</strong>
                        </Trans>
                    </DialogContentText>

                    <Stack spacing={1}>
                        <Controller name='email' control={control}
                            render={({ field, fieldState }) => (
                                <TextField {...field} autoFocus margin='dense' type='email' fullWidth required
                                    label={t('invitations.inviteDialog.email')}
                                    error={!!fieldState.error}
                                    helperText={fieldState.error?.message}
                                />
                            )}
                        />
                        <FormControl fullWidth>
                            <InputLabel id='role-label'>{t('invitations.inviteDialog.role')}</InputLabel>
                            <Controller id='property-role' name='propertyRole' control={control}
                                render={({ field }) => (
                                    <Select {...field} label={t('invitations.inviteDialog.role')} labelId='role-label'>
                                        {AVAILABLE_ROLES.map(item =>
                                            <MenuItem key={item} value={item}>{t(MemberRoleModel.i18nKeyPropertyRole(item))}</MenuItem>)
                                        }
                                    </Select>
                                )}
                            />
                        </FormControl>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        type='submit'
                        disabled={!isValid}
                    >{t('actions.invite')}</Button>
                    <Button onClick={onCancel}>{t('actions.cancel')}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const editMemberSchema = yup.object({
    propertyRole: yup.string().oneOf(AVAILABLE_ROLES).required()
}).required();
const EditMemberDialog = ({ member, open, onCancel, onSubmit, onRemove }) => {
    const { t } = useTranslation();
    const [isSafeToReset, setIsSafeToReset] = useState(false);

    const { control, reset, handleSubmit, formState: { isValid, isDirty } } = useForm({
        defaultValues: {
            propertyRole: member.role
        },
        resolver: yupResolver(editMemberSchema)
    });

    // https://react-hook-form.com/api/useform/reset
    const onSubmitWrapper = async (data, e) => {
        setIsSafeToReset(false);
        await onSubmit(member, data, e);
        setIsSafeToReset(true);
    };
    useEffect(() => {
        if (!isSafeToReset) return;
        reset();
    }, [reset, isSafeToReset]);

    return (
        <Dialog open={open} onClose={onCancel}>
            <form onSubmit={handleSubmit(onSubmitWrapper)}>
                <DialogTitle>
                    <Typography component='span' sx={{ mr: 3, fontWeight: 'bold' }}>{t('properties.editMemberDialog.title', { member: member.displayName })}</Typography>
                    {!!onRemove && <Button startIcon={<PersonRemove />} size='small' variant='outlined' color='error' onClick={() => onRemove(member)}>{t('actions.remove')}</Button>}
                </DialogTitle>
                <DialogContent>
                    <Stack spacing={1}>
                        <FormControl fullWidth sx={{ mt: 2 }}>
                            <InputLabel id='editmember-role-label'>{t('properties.editMemberDialog.role')}</InputLabel>
                            <Controller id='editmember-property-role' name='propertyRole' control={control}
                                render={({ field }) => (
                                    <Select {...field} label={t('properties.editMemberDialog.role')} labelId='editmember-role-label'>
                                        {AVAILABLE_ROLES.map(item =>
                                            <MenuItem key={item} value={item}>{t(MemberRoleModel.i18nKeyPropertyRole(item))}</MenuItem>)
                                        }
                                    </Select>
                                )}
                            />
                        </FormControl>

                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant='contained'
                        type='submit'
                        disabled={!isValid || !isDirty}
                    >{t('actions.save')}</Button>
                    <Button onClick={onCancel}>{t('actions.cancel')}</Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

const MembershipPropertyTab = ({ property, query, variables, model: propertyModel }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { displaySuccess, displayError } = useSnackbar();
    const { callbackWithError } = useSnackbarApolloCallback();
    const { auth0User } = useAuth0();

    const hasEditClaims = auth0User && propertyModel.memberWithIdHasClaims(auth0User.id, PropertyClaims.RemoveMember, PropertyClaims.ChangeMemberRole);
    const hasInviteClaim = auth0User && propertyModel.memberWithIdHasClaims(auth0User.id, PropertyClaims.InviteMember);
    const hasRemoveClaim = auth0User && propertyModel.memberWithIdHasClaims(auth0User.id, PropertyClaims.RemoveMember);
    const hasLeaveClaim = auth0User && propertyModel.memberWithIdHasClaims(auth0User.id, PropertyClaims.LeaveProperty);

    // inviteToProperty, cancelInvitation mutation
    const { inviteToProperty } = useInviteToProperty();
    const { cancelInvitation } = useCancelInvitation();

    // changeMemberRole, removeMemberFromProperty, and leaveProperty mutations
    const refetch = { refetchQuery: query, refetchVariables: variables };
    const { changeMemberRole } = useChangeMemberRole(refetch);
    const { removeMemberFromProperty } = useRemoveMemberFromProperty(refetch);
    const { leaveProperty } = useLeaveProperty(refetch);

    // invitationsForSender query
    const {
        senderInvitations,
        loading: senderInvitationsLoading,
        error: senderInvitationsError
    } = useInvitationsForSender(hasInviteClaim);

    // dialog state
    const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
    const [editMemberDialogMember, setEditMemberDialogMember] = useState(null);

    // invite member action
    const handleInviteDialogSubmit = async (data) => {
        setInviteDialogOpen(false);
        return inviteToProperty(
            data.email, propertyModel.id, data.propertyRole,
            callbackWithError(t('invitations.notification.inviteSuccess', { email: data.email }))
        );
    };

    // delete invitation action
    const handleInvitationDelete = async (invitationModel) => {
        return cancelInvitation(
            invitationModel.id,
            callbackWithError(t('invitations.notification.cancelInviteSuccess', { email: invitationModel.recipient }))
        );
    };

    // change member role
    const handleEditMemberDialogSubmit = async (memberModel, data) => {
        setEditMemberDialogMember(null);
        return changeMemberRole(propertyModel.id, memberModel.userId, data.propertyRole, property, memberModel.id,
            callbackWithError(t('properties.notification.updateRoleSuccess', { member: memberModel.displayName }))
        );
    };

    // remove property member
    const handleRemoveMember = async (memberModel) => {
        setEditMemberDialogMember(null);
        return removeMemberFromProperty(propertyModel.id, memberModel.userId, property, memberModel.id,
            callbackWithError(t('properties.notification.removeMemberSuccess', { member: memberModel.displayName }))
        );
    };

    // onConfirmLeaveProperty
    const onConfirmLeaveProperty = (closeDialog) => leaveProperty(propertyModel.id, {
        onError: (error) => {
            closeDialog();
            return displayError(error.display());
        },
        onCompleted: (leaveProperty) => {
            closeDialog();
            displaySuccess(t('properties.notification.leaveSuccess', { propertyName: leaveProperty.name }));
            return navigate(routes.properties.dashboard);
        }
    });

    const filteredInvitations = senderInvitations?.filter(inv => inv.propertyId === property._id) ?? [];
    const width = { xs: 1, sm: '80%', md: '50%' };
    return (
        <CommonPropertyTab property={property}>
            {/** Invite Button **/}
            <Button
                onClick={() => setInviteDialogOpen(true)}
                startIcon={<PersonAdd />}
                variant='contained'
                disabled={!hasInviteClaim}
            >{t('actions.invite')}</Button>
            {!hasInviteClaim && <Typography variant='body2' color='text.secondary'>{t('invitations.disabledInviteReason')}</Typography>}

            {/** Member List **/}
            <Box sx={{ width }}>
                <Typography variant='h5' sx={{ pt: 2 }}>{t('properties.labels.propertyMembership')}</Typography>
                <List>
                    {propertyModel.members.map((m, idx) => {
                        const memberModel = new MemberRoleModel(m);
                        return (
                            <MemberItem key={m._id}
                                member={memberModel}
                                canEdit={hasEditClaims && memberModel.canEditProperty(auth0User.id)}
                                divider={idx + 1 < propertyModel.memberCount()}
                                onEditMember={() => setEditMemberDialogMember(memberModel)}
                            />
                        );
                    })}
                </List>
            </Box>

            {/** Invite List **/}
            {hasInviteClaim &&
                <Box sx={{ width }}>
                    <Typography variant='h5' sx={{ pt: 2 }}>{t('invitations.pendingInvitations')}</Typography>
                    {senderInvitationsLoading && <CircularProgress />}
                    {!senderInvitationsLoading && !senderInvitationsError && filteredInvitations.length > 0 &&
                        <List>
                            {filteredInvitations.map((item, idx) =>
                                <InvitationItem key={item._id}
                                    invitation={item}
                                    divider={idx + 1 < filteredInvitations.length}
                                    onDelete={handleInvitationDelete}
                                />)
                            }
                        </List>
                    }
                    {!senderInvitationsLoading && !senderInvitationsError && filteredInvitations.length === 0 &&
                        <Typography variant='body1' color='text.secondary'>{t('invitations.noInvitations')}</Typography>
                    }
                </Box>
            }

            {/** Leave Property **/}
            {hasLeaveClaim &&
                <DangerZone
                    sectionTitle={t('properties.leaveTitle')}
                    disabled={false}
                    loading={false}
                    dialogContent={t('properties.leaveConfirmation')}
                    destructiveButtonText={t('actions.leave')}
                    onDialogConfirm={onConfirmLeaveProperty}
                >
                    {t('properties.leaveWarning')}
                </DangerZone>
            }

            {/** Dialogs **/}
            <InviteDialog
                open={inviteDialogOpen}
                propertyName={propertyModel.name}
                onCancel={() => setInviteDialogOpen(false)}
                onSubmit={handleInviteDialogSubmit}
            />
            {editMemberDialogMember &&
                <EditMemberDialog
                    open={!!editMemberDialogMember}
                    member={editMemberDialogMember}
                    onCancel={() => setEditMemberDialogMember(null)}
                    onSubmit={handleEditMemberDialogSubmit}
                    onRemove={hasRemoveClaim ? handleRemoveMember : null}
                />
            }
        </CommonPropertyTab>
    );
};

export default MembershipPropertyTab;