class AbstractModel {
    constructor(entity) {
        this.entity = entity;
    }

    get _id() {
        return this.entity._id;
    }
    get id() {
        return this._id;
    }
}

export default AbstractModel;