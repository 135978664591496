import {
    createBrowserRouter
} from 'react-router-dom';

import { ProtectedComponent } from './auth/Auth0Components';
import Home from './home/Home';
import Profile from './profile/Profile';
import Landing from './public/Landing';
import Properties from './property/Properties';
import NewProperty from './property/NewProperty';
import PropertyHome from './property/PropertyHome';
import PropertyDetails from './property/PropertyDetails';
import Root from './components/Root';
import Reservations from './reservation/Reservations';
import ReservationManager from './reservation/ReservationManager';
import LogoutLanding from './public/LogoutLanding';
import LoginRedirect from './components/LoginRedirect';
import { routes, exact, home, profile, properties, reservations, logout, login, _new } from './routes.mjs';

export const router = createBrowserRouter([
    {
        path: routes.root,
        element: <Root />,
        children: [
            {path: exact, exact: true, element: <Landing />},
            {path: login, exact: true, element: <LoginRedirect /> },
            {path: logout, exact: true, element: <LogoutLanding />},
            {path: home, element: <ProtectedComponent component={Home} />},
            {path: profile, element: <ProtectedComponent component={Profile} />},
            {
                path: properties,
                element: <ProtectedComponent component={Properties} />,
                children: [
                    {path: exact, exact: true, element: <ProtectedComponent component={PropertyHome} />},
                    {path: _new, element: <ProtectedComponent component={NewProperty} />},
                    {path: ':id', element: <ProtectedComponent component={PropertyDetails} />}
                ]
            },
            {
                path: reservations,
                element: <ProtectedComponent component={Reservations} />,
                children: [
                    {path: exact, exact: true, element: <ProtectedComponent component={ReservationManager} heightOffset={200} />}
                ]
            },
        ]
    }
]);