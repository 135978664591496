import dayjs from 'dayjs';

import AbstractModel from './model.mjs';

const hash = (str) => {
    let hashString = 0;
    for (let character of str) {
        let charCode = character.charCodeAt(0);
        hashString = (hashString << 5) - hashString + charCode;
        hashString |= hashString;
    }
    return hashString;
};

export const formatDateRange = (startDate, endDate) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);

    if (start.isSame(end, 'day')) {
        return start.format('MMM D, YYYY');
    } else {
        const startFormat = `MMM D${start.isSame(end, 'year') ? '' : ', YYYY'}`;
        const endFormat = `${start.isSame(end, 'month') ? '' : 'MMM '}D, YYYY`;
        return `${start.format(startFormat)} - ${end.format(endFormat)}`;
    }
};

export class ReservationModel extends AbstractModel {

    get name() {
        return this.entity.name;
    }
    get startDate() {
        return this.entity.startDate;
    }
    get endDate() {
        return this.entity.endDate;
    }

    get userPicture() {
        return this.entity.user.picture;
    }
    get userDisplayName() {
        return this.entity.user.displayName;
    }
    get userEmail() {
        return this.entity.user.email;
    }

    get property() {
        return this.entity.property;
    }
    get propertyId() {
        return this.entity.property._id;
    }
    get canEdit() {
        return this.entity.editable?.edit ?? false;
    }
    get canDelete() {
        return this.entity.editable?.delete ?? false;
    }

    dateRangeDisplay() {
        return formatDateRange(this.entity.startDate, this.entity.endDate);
    }
    displayName(displayProperty = true) {
        return [this.entity.name, displayProperty ? this.entity.property.name : ''].filter(item => item?.length > 0).join(' / ')
    }
    displayEvent(displayProperty = true) {
        return [
            this.userDisplayName,
            displayProperty ? this.entity.property.name : '',
            this.entity.name
        ].filter(item => item?.length > 0).join(' / ')
    }

    isOwnerById(id) {
        return this.entity.user?._id === id;
    }

    asCalendarEvent(displayProperty = true) {
        // https://fullcalendar.io/docs/event-object
        return {
            id: this.entity._id,
            allDay: true,
            title: this.displayEvent(displayProperty),
            start: this.startDate,
            end: dayjs(this.endDate).add(1, 'day').toDate(), // add 1 day because FullCalendar view id exclusive
            colorHash: hash(this.userEmail)
        };
    }
}