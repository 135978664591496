import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';

import { useAuth0 } from '../auth/useAuth.js';

const LoginRedirect = () => {
    const { t } = useTranslation();
    const { isAuthenticated, loginWithRedirect } = useAuth0();

    useEffect(() => {
        if (isAuthenticated) return;
        loginWithRedirect();
    }, [isAuthenticated, loginWithRedirect]);

    return (
        <Typography variant='body2'>{t('redirecting')}</Typography>
    );
};

export default LoginRedirect;