import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Controller } from 'react-hook-form';
import { MAX_NAME_LENGTH } from '@allocamp/common';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers-pro';
import { AdapterDayjs } from '@mui/x-date-pickers-pro/AdapterDayjs';

import i18n from '../i18n.js';
import DateRangePicker, { DateRangeSchema } from './DateRangePickerPro.js';

const maxLengthErrorMessage = ({ max, value }) => i18n.t('validation.nameMaxLength', { maxLength: max, currentLength: value.length });

export const UpdateReservationSchema = DateRangeSchema.concat(yup.object({
    name: yup.string().trim().max(MAX_NAME_LENGTH, maxLengthErrorMessage)
}));
export const CreateReservationSchema = UpdateReservationSchema.concat(yup.object({
    propertyId: yup.string().required()
}));

const EditReservation = ({ formMethods, disabled, readOnly, properties }) => {
    const { t } = useTranslation();
    const { control } = formMethods;

    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <Stack spacing={2} sx={{ my: 1 }}>
                <Controller name='name' control={control}
                    render={({ field, fieldState }) => (
                        <TextField {...field} fullWidth
                            disabled={disabled}
                            label={t('reservations.input.name')}
                            placeholder={t('reservations.input.namePlaceholder')}
                            error={!!fieldState.error}
                            helperText={fieldState.error?.message}
                            InputProps={{ readOnly }}
                        />
                    )}
                />
                {properties &&
                    <Controller name='propertyId' control={control}
                        render={({ field }) => (
                            <FormControl fullWidth>
                                <InputLabel id='property-selector-label'>{t('reservations.input.property')}</InputLabel>
                                <Select {...field}
                                    labelId='property-selector-label'
                                    label={t('reservations.input.property')}
                                    disabled={disabled}
                                >
                                    {properties.map(p => <MenuItem key={p._id} value={p._id}>{p.name}</MenuItem>)}
                                </Select>
                            </FormControl>
                        )}
                    />
                }
                <DateRangePicker
                    control={control}
                    disabled={disabled}
                    readOnly={readOnly}
                />
            </Stack>
        </LocalizationProvider>
    );
};

export default EditReservation;